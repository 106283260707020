import React, { useState } from 'react';
import {
    Box,
    Divider,
    Grid,
    Typography,
    Modal,
    Fade,
    Backdrop,
    Link as MuiLink,
    Card,
    CardActionArea,
    CardMedia,
    useMediaQuery,
} from '@mui/material';
import { BLOCKS, MARKS, INLINES, Document as ContentfulDocument } from '@contentful/rich-text-types';
import parse from 'html-react-parser';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { styled, useTheme } from '@mui/material/styles';

export interface FeatureDetails {
    title: string;
    features: Feature[];
}

interface Feature {
    title: string;
    description: ContentfulDocument;
    icon: Icon;
    finePrint: string;
}

interface Icon {
    file: {
        url: string;
    };
}

const StyledModal = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const Bold = ({ children }) => (
    <Typography component="span" fontWeight="bold">
        {children}
    </Typography>
);
const Italic = ({ children }) => (
    <Typography component="span" fontStyle="italic">
        {children}
    </Typography>
);
const Text = ({ children }) => <Typography className="">{children}</Typography>;

const options = {
    renderMark: {
        [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,

        [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,

        [MARKS.CODE]: (text: string) => parse(`${text}`),
    },

    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,

        [INLINES.HYPERLINK]: (node, children) => (
            <MuiLink href={node.data.uri} onClick={(e) => closeModal(e)}>
                {children}
            </MuiLink>
        ),
    },
};
function Features({ featureDetails }) {
    const blankFeature = {
        icon: { file: { url: '' } },
        title: '',
        description: { raw: null },
        finePrint: '',
    };
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedFeature, setSelectedFeature] = useState<Feature>(blankFeature);
    const [selectedFeatureIcon, setSelectedFeaturedIcon] = useState<string>('');
    const theme = useTheme();
    const isSmall: Boolean = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile: Boolean = useMediaQuery(theme.breakpoints.down('sm'));

    const FeatureBox = styled(Grid)({
        display: 'flex',
        flexDirection: 'row',
        height: '88px',
        width: isSmall ? '104px' : '183px',
        '&:hover': {
            cursor: 'pointer',
        },
        background: '#F6F0FC',
        boxShadow: ' 0px 4px 16px rgba(0, 0, 0, 0.24)',
        borderRadius: ' 8px',
        padding: '16px',
    });

    const closeModal = (feature: Feature) => {
        setSelectedFeature(blankFeature);
        setShowModal(false);
    };

    const openModal = (feature: Feature) => {
        setSelectedFeature(feature);
        setShowModal(true);
    };

    const StyledFeaturesTypography = styled(Typography)({
        fontSize: isSmall ? '.65rem' : '1rem',
        lineHeight: '16px',
    });

    return (
        <Grid height="auto" display="flex" flexDirection="column" mt={2} container>
            <Grid
                height="fit-content"
                display="flex"
                flexDirection="row"
                justifyContent="space-evenly"
                item
                container
                mb={1}
            >
                <Grid item>
                    <Typography fontSize={isSmall ? '1rem' : '1.5rem'} color="text.white">
                        {featureDetails.title}
                    </Typography>
                </Grid>
                <Grid xs pl={1} item>
                    <Divider color="#D5D5D5" flexItem sx={{ marginTop: '1em' }} />
                </Grid>
            </Grid>
            <Grid display="flex" flexDirection="row" justifyContent="space-evenly" container>
                {featureDetails.features.map((feature: Feature, index: number) =>
                    isSmall ? (
                        <Grid sm={2} xs={4} display="flex" flexDirection="row" justifyContent="center" container mb={1}>
                            <FeatureBox
                                display="flex"
                                flexDirection="column"
                                width="104px"
                                onClick={() => openModal(feature)}
                                id={`btn-home-sns-feature-${index}`}
                                sx={{
                                    '&:hover': {
                                        cursor: 'pointer',
                                    },
                                    background: '#F6F0FC',
                                    boxShadow: ' 0px 4px 16px rgba(0, 0, 0, 0.24)',
                                    borderRadius: ' 8px',
                                    p: 1,
                                }}
                                item
                                container
                            >
                                <Grid
                                    height="50%"
                                    sm={12}
                                    xs={12}
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Box component="img" height="90%" src={feature.icon.file.url} alt={feature.title} />
                                </Grid>
                                <Grid
                                    sm={12}
                                    xs={12}
                                    height="50%"
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <StyledFeaturesTypography color="primary" textAlign="center">
                                        {feature.title}
                                    </StyledFeaturesTypography>
                                </Grid>
                            </FeatureBox>
                        </Grid>
                    ) : (
                        <Grid md={2} sm={2} display="flex" flexDirection="row" justifyContent="center" container>
                            <FeatureBox
                                onClick={() => openModal(feature)}
                                sx={{}}
                                item
                                container
                                id={`btn-home-sns-feature-${index}`}
                            >
                                <Grid height="100%" display="flex" flexDirection="row" alignItems="center" xs={3}>
                                    <Box
                                        component="img"
                                        height="70%"
                                        width="100%"
                                        src={feature.icon.file.url}
                                        alt={feature.title}
                                    />
                                </Grid>
                                <Grid
                                    height="100%"
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    xs={9}
                                    pl={1}
                                >
                                    <StyledFeaturesTypography color="primary">{feature.title}</StyledFeaturesTypography>
                                </Grid>
                            </FeatureBox>
                        </Grid>
                    )
                )}
                <StyledModal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={showModal}
                    onClose={closeModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={showModal}>
                        <Grid display="flex" flexDirection="row" alignItems="center" justifyContent="center" container>
                            <Grid direction="row" item sx={{ zIndex: 1 }}>
                                <Card
                                    raised={true}
                                    sx={{
                                        // minHeight: '294px',
                                        width: '375px',
                                        background: 'linear-gradient(106.14deg, #F3E9FB 0%, #E9D7F9 100%)',
                                        py: 2,
                                        px: 4,
                                    }}
                                >
                                    <Grid
                                        height="100%"
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="space-evenly"
                                        container
                                    >
                                        <Box display="flex" alignItems="end" justifyContent="center">
                                            <Box
                                                component="img"
                                                height="42px"
                                                src={selectedFeature.icon.file.url}
                                                alt={selectedFeature.title}
                                            />
                                        </Box>

                                        <Box
                                            height={selectedFeature.finePrint === '' ? '80%' : '70%'}
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                        >
                                            <Typography fontSize="1.5rem" color="#671CA6" textAlign="center" my={2}>
                                                {selectedFeature.title}
                                            </Typography>
                                            <Typography fontSize="1rem" textAlign="center">
                                                {selectedFeature.raw !== null &&
                                                    renderRichText(selectedFeature.description, options)}
                                            </Typography>
                                        </Box>
                                        {selectedFeature.finePrint !== '' && selectedFeature.finePrint !== null && (
                                            <Box height="10%" display="flex" flexDirection="column" alignItems="center">
                                                <Typography fontSize=".75rem" color="text.disabled" pt={1}>
                                                    {selectedFeature.finePrint}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Fade>
                </StyledModal>
            </Grid>
        </Grid>
    );
}

export default Features;
