import React, { memo, useEffect } from 'react';
import {
    Container,
    Box,
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Link as MuiLink,
    useMediaQuery,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme, styled, makeStyles } from '@mui/material/styles';
import { BLOCKS, MARKS, INLINES, Document as ContentfulDocument } from '@contentful/rich-text-types';
import parse from 'html-react-parser';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

export const StyledAccordion = styled(Accordion)({
    borderRadius: '16px !important',
    width: 'auto',
    color: '#8223D2',
    '&:nth-of-type(1n)': {
        border: '1px solid #D5D6DA',
    },
    '.Mui-expanded': {
        backgroundColor: '#F3E9FB',
        borderTopLeftRadius: '16px !important',
        borderTopRightRadius: '16px !important',
    },
});

export const StyledAccordionDetails = styled(AccordionDetails)({
    backgroundColor: '#F3E9FB',
    color: 'black',
    fontSize: '1rem',
    borderRadius: '16px',
    borderTopRightRadius: '0',
    borderTopLeftRadius: '0',
});

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)({
    fill: '#8223D2',
});

const Bold = ({ children }) => (
    <Typography component="span" fontWeight="bold">
        {children}
    </Typography>
);
const Italic = ({ children }) => (
    <Typography component="span" fontStyle="italic" color="text.secondary">
        {children}
    </Typography>
);
const Text = ({ children }) => <Typography className="">{children}</Typography>;

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
        [INLINES.HYPERLINK]: (node, children) => <AnchorLink to={node.data.uri}>{children}</AnchorLink>,
    },
    renderText: (text) => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
    renderMarks: {
        [MARKS.BOLD]: (node, children) => <strong>{children}</strong>,
    },
};

const FAQSave = ({ faqData, faqTitle, faqType }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box sx={{ background: faqType ? 'linear-gradient(162.46deg, #7720C0 0%, #57178C 100%)' : '' }} id={faqType}>
            <Container maxWidth="xl" sx={{ maxWidth: '1400px !important', padding: '5%' }}>
                <Grid flexDirection="column" display="flex" justifyContent="center" textAlign="center" sx={{ mb: 2 }}>
                    <Typography sx={{ fontSize: isMobile ? '1.5rem' : '2rem', color: faqType ? '#fff' : '#000' }}>
                        {faqTitle}
                    </Typography>
                </Grid>
                <Grid container>
                    {isMobile
                        ? faqData !== null &&
                          faqData.map((query, index) => (
                              <Grid sm={12} sx={{ my: 1, width: '100%' }} key={`question${index}`} item>
                                  <StyledAccordion disableGutters elevation={0} key={`query-${index}`}>
                                      <AccordionSummary
                                          aria-controls="panel1d-content"
                                          expandIcon={<StyledExpandMoreIcon />}
                                          id="btn-save-faqs-question-ans"
                                      >
                                          <Typography
                                              fontSize={isMobile ? '1.125rem' : '1.5rem'}
                                              lineHeight={isMobile ? '1.5' : '1.5rem'}
                                          >
                                              {query.question}
                                          </Typography>
                                      </AccordionSummary>
                                      <StyledAccordionDetails>
                                          <Typography
                                              fontSize={isMobile ? '1.125rem' : '1rem'}
                                              lineHeight={isMobile ? '1.5' : '1rem'}
                                              sx={{ wordBreak: 'break-word' }}
                                          >
                                              {query.answer && renderRichText(query.answer, options)}
                                          </Typography>
                                      </StyledAccordionDetails>
                                  </StyledAccordion>
                              </Grid>
                          ))
                        : faqData !== null &&
                          faqData.map((query, index) => (
                              <Grid sm={6} sx={{ my: 2, px: 2 }} key={`question${index}`} item>
                                  <StyledAccordion disableGutters elevation={0} key={`query-${index}`}>
                                      <AccordionSummary
                                          aria-controls="panel1d-content"
                                          expandIcon={<StyledExpandMoreIcon />}
                                          id="btn-save-faqs-question-ans"
                                      >
                                          <Typography fontSize={isMobile ? '1.125rem' : '1rem'} lineHeight={'1'}>
                                              {query.question}
                                          </Typography>
                                      </AccordionSummary>
                                      <StyledAccordionDetails>
                                          <Typography
                                              fontSize={isMobile ? '1.125rem' : '1rem'}
                                              lineHeight={'1'}
                                              sx={{ wordBreak: 'break-word' }}
                                          >
                                              {query.answer && renderRichText(query.answer, options)}
                                          </Typography>
                                      </StyledAccordionDetails>
                                  </StyledAccordion>
                              </Grid>
                          ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default FAQSave;
