import React, { useEffect, useState, useRef } from 'react';
import { Box, Grid, Typography, Link, Button, MenuItem, FormControl, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Document as ContentfulDocument } from '@contentful/rich-text-types';
import DeviceDetector from 'device-detector-js';
import { redirectWithParams, checkCookieName } from '../../utils/Utils';
import { mobileVendor } from 'react-device-detect';

export interface PlanSelectionDetails {
    devices: Device[];
    plans: Plan[];
    featuresTitle: string;
    planSelectionTitle: string;
    planSelectionDescription: string;
    planSelectionInstruction: string;
    ctaButtonText: string;
}

export interface Device {
    id: string;
    modelId: string;
    deviceTitle: string;
    deviceSlug: string;
    asurionLitePlanMonthlyPrice: string;
    asurionLitePlanYearlyPrice: string;
    viewportWidth: string;
    viewportHeight: string;
}

export interface Plan {
    autoSelectUrlPath: string;
    olderPhonesExpanderText: ContentfulDocument;
    id: string;
    addedExtras: ContentfulDocument;
    additionalNotes: ContentfulDocument;
    asurionPlanComparison: string;
    breakdownText: string;
    cancelAnytimeDescription: ContentfulDocument;
    cancelAnytimeDetails: string;
    cancelAnytimeExpanderText: string;
    cancelAnytimeRemark: boolean;
    changePhoneOrPlanDetails: string;
    changePhoneOrPlanExpanderText: string;
    changePhoneOrPlanRemark: true;
    company: string;
    damageReplacementDescription: ContentfulDocument;
    damageReplacementDetails: string;
    damageReplacementRemark: boolean;
    insuranceRemark: boolean;
    lostOrStolenDescription: ContentfulDocument;
    lostOrStolenDetails: string;
    lostOrStolenExpanderText: string;
    lostOrStolenRemark: boolean;
    name: string;
    noClaimsDetails: string;
    noClaimsExpanderText: string;
    noClaimsRemark: boolean;
    olderPhonesDetails: string;
    olderPhonesRemark: boolean;
    olderPhonesDescription: ContentfulDocument;
    phonePricing: PhonePricing[];
    phonePurchaseDetails: string;
    phonePurchaseRemark: boolean;
    screenPhoneRepairDescription: ContentfulDocument;
    screenPhoneRepairDetails: string;
    screenPhoneRepairRemark: boolean;
    howToCancelFaqs: FAQ[];
    planSpecificFaqs: FAQ[];
}

export interface FAQ {
    question: string;
    answer: ContentfulDocument;
}

export interface PhonePricing {
    deviceName: Device;
    asurionLitePlanMonthlyPrice: string;
    asurionLitePlanYearlyPrice: string;
    monthlyPrice: string;
    annualPrice: string;
    biannualPrice: string;
}

interface UserDevice {
    brand: string;
    model: string;
    type: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const MenuPropsMobile = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
        sx: { height: '170px!important' },
    },
};
export const StyledSelect = styled(Select)({
    borderRadius: '8px',
    '& .MuiInputBase-input': { border: '1px solid #8223D2', borderRadius: '8px' },
});

function PlanSelection({ planSelectionInfo, onDeviceConfirmed, onPlanConfirmed, setShowFaqs }) {
    const [loadingPlans, setLoadingPlans] = useState<boolean>(false);
    const [loadingDevices, setLoadingDevices] = useState<boolean>(false);
    const [selectedDevice, setSelectedDevice] = useState<Device | string>('');
    const [selectedDeviceDetails, setSelectedDeviceDetails] = useState<Device>();
    const [selectedPlan, setSelectedPlan] = useState<Plan | string>('');
    const [availableDevices, setAvailableDevices] = useState<Device[]>([]);
    const [availablePlans, setAvailablePlans] = useState<Plan[]>([]);
    const planSelectionDetails: PlanSelectionDetails = planSelectionInfo;
    const [allPlans, setAllPlans] = useState<Plan[]>([]);
    const [allDevices, setAllDevices] = useState<Device[]>([]);
    const [litePlanMonthlyPrice, setLitePlanMonthlyPrice] = useState<string>('4.35');
    const [litePlanYearlyPrice, setLitePlanYearlyPrice] = useState<string>('47.40');
    const [isPlanInputDirty, setIsPlanInputDirty] = useState<boolean>(false);
    const [isPlanAutoSelected, setIsPlanAutoSelected] = useState<boolean>(false);
    const [isDeviceAutoSelected, setIsDeviceAutoSelected] = useState<boolean>(false);
    const theme = useTheme();
    const isSmall: Boolean = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile: Boolean = useMediaQuery(theme.breakpoints.down('sm'));
    const isBrowser: boolean = typeof window !== 'undefined';
    const comparePlansButtonRef = useRef(null);

    const filterPlansPerDevice = (deviceId: string) => {
        setLoadingPlans(true);
        let plansWithSelectedDevice: Plan[] = [];
        //if no phone is selected, give all plans else filter plans with selected phone
        if (deviceId === '') {
            plansWithSelectedDevice = allPlans;
        } else {
            for (let i = 0; i < allPlans.length; i++) {
                if (allPlans[i].phonePricing.filter((pricing) => pricing.deviceName.id.includes(deviceId)).length > 0) {
                    plansWithSelectedDevice.push(allPlans[i]);
                }
            }
        }
        setAvailablePlans(plansWithSelectedDevice);
        setLoadingPlans(false);
    };

    const handleDeviceChange = (event: SelectChangeEvent) => {
        let selectedDevice: Device = planSelectionDetails.devices.find((device) =>
            device.id.includes(event.target.value)
        );
        if (event.target.value !== '') {
            setLitePlanMonthlyPrice(selectedDevice.asurionLitePlanMonthlyPrice);
            setLitePlanYearlyPrice(selectedDevice.asurionLitePlanYearlyPrice);
            setSelectedDeviceDetails(selectedDevice);
        } else {
            setLitePlanMonthlyPrice('4.35');
            setLitePlanYearlyPrice('47.40');
        }
        setSelectedDevice(event.target.value as string);
        filterPlansPerDevice(event.target.value);
    };

    const autoDeviceChange = (deviceId: string) => {
        if (isBrowser) {
            if (location.pathname === '/switch' || location.pathname === '/switch/') {
                let selectedDevice: Device = planSelectionDetails.devices.find((device) =>
                    device.id.includes(deviceId)
                );
                if (deviceId !== '') {
                    setLitePlanMonthlyPrice(selectedDevice.asurionLitePlanMonthlyPrice);
                    setLitePlanYearlyPrice(selectedDevice.asurionLitePlanYearlyPrice);
                    setSelectedDeviceDetails(selectedDevice);
                } else {
                    setLitePlanMonthlyPrice('4.35');
                    setLitePlanYearlyPrice('47.40');
                }
                setSelectedDevice(deviceId as string);
                filterPlansPerDevice(deviceId);
            }
        }
    };

    const filterDevicesPerPlan = (planId: string) => {
        setLoadingDevices(true);
        let devicesWithSelectedPlan: Device[] = [];

        if (planId !== '') {
            let plan: Plan = allPlans.find((plan) => plan.id.includes(planId));
            for (let i = 0; i < plan.phonePricing.length; i++) {
                devicesWithSelectedPlan.push(plan.phonePricing[i].deviceName);
            }
        } else {
            devicesWithSelectedPlan = planSelectionDetails.devices;
        }

        setAvailableDevices(devicesWithSelectedPlan);
        if (!devicesWithSelectedPlan.find((device) => device.id === selectedDevice)) {
            setSelectedDevice('');
            setSelectedDeviceDetails(null);
        }
        setLoadingDevices(false);
    };

    const handlePlanChange = (event: SelectChangeEvent) => {
        setIsPlanInputDirty(true);
        setSelectedPlan(event.target.value as string);
        filterDevicesPerPlan(event.target.value);
        return;
    };

    const comparePlans = () => {
        setShowFaqs(false);
        let planForComparison: Plan = allPlans.find((plan) => plan.id.includes(selectedPlan));
        if (selectedPlan !== '') {
            let deviceForComparison: Device = allDevices.find((device) => device.id.includes(selectedDevice));
            onDeviceConfirmed(deviceForComparison);
        } else {
            onDeviceConfirmed(selectedPlan);
        }
        onPlanConfirmed(planForComparison);
        pushToDataLayer('Home_DL_Event SNS Compare Plans', planForComparison.asurionPlanComparison[0]);
    };

    const pushToDataLayer = (event: string, plan: string) => {
        if (isBrowser) {
            window.dataLayer.push({
                event: event,
                GCLID: checkCookieName('gclid') || null,
                GAClientID:
                    (checkCookieName('_ga') &&
                        checkCookieName('_ga').substring(checkCookieName('_ga').indexOf('.') + 3)) ||
                    null,
                UTMParams: {
                    utm_source: checkCookieName('utm_source') || null,
                    utm_content: checkCookieName('utm_content') || null,
                    utm_id: checkCookieName('utm_id') || null,
                    utm_campaign: checkCookieName('utm_campaign') || null,
                    utm_medium: checkCookieName('utm_medium') || null,
                    utm_term: checkCookieName('utm_term') || null,
                },
                DeviceName: selectedDeviceDetails.deviceTitle || null,
                AsurionPlanName: plan || null,
            });
        }
    };

    useEffect(() => {
        setAllPlans(planSelectionDetails.plans);
        setAvailablePlans(planSelectionDetails.plans);
    }, [planSelectionDetails.plans]);

    useEffect(() => {
        setAllDevices(planSelectionDetails.devices);
        setAvailableDevices(planSelectionDetails.devices);
    }, [planSelectionDetails.devices]);

    useEffect(() => {
        //Detect user device and will autofill if device is supported by plan
        if (typeof window !== 'undefined') {
            let listOfDevices: Device[] = allDevices.filter((device) => device.viewportHeight && device.viewportWidth);
            const width = window.innerWidth.toString();
            const height = window.innerHeight.toString();
            let possibleUserDevicesByModel: Device[] = listOfDevices.filter((device) =>
                device.deviceTitle.toLowerCase().includes(mobileVendor.toLowerCase())
            );

            let possibleUserDevicesBySize: Device[] = possibleUserDevicesByModel.filter(
                (device) => device.viewportHeight.includes(height) && device.viewportWidth.includes(width)
            );

            if (possibleUserDevicesBySize.length > 0) {
                autoDeviceChange(possibleUserDevicesBySize[0].id);
            }
        }
    }, [allPlans]);

    useEffect(() => {
        //Set selected plan and device based on url path
        if (isBrowser) {
            let path: string = window.location.pathname;
            let planByUrl: Plan;
            let deviceByUrl: Device;
            for (let i: number = 0; i < allPlans.length; i++) {
                if (allPlans[i].autoSelectUrlPath) {
                    if (path.includes(allPlans[i].autoSelectUrlPath)) {
                        path = path.replace(allPlans[i].autoSelectUrlPath, '');

                        planByUrl = allPlans[i];
                        break;
                    }
                }
            }
            for (let deviceIndex: number = 0; deviceIndex < allDevices.length; deviceIndex++) {
                if (path.replace('/', '') === allDevices[deviceIndex].deviceSlug) {
                    deviceByUrl = allDevices[deviceIndex];
                    break;
                }
            }
            setIsPlanInputDirty(true);
            if (planByUrl) {
                filterDevicesPerPlan(planByUrl.id);
                setSelectedPlan(planByUrl.id as string);
                setIsPlanAutoSelected(true);
            }
            if (deviceByUrl) {
                setSelectedDeviceDetails(deviceByUrl);
                setSelectedDevice(deviceByUrl.id as string);
                setIsDeviceAutoSelected(true);
            }
        }
    }, [allPlans]);

    useEffect(() => {
        if (isPlanAutoSelected && isDeviceAutoSelected) {
            comparePlansButtonRef.current?.click();
        }
        if (isPlanAutoSelected && !isDeviceAutoSelected) {
            // setShowFaqs(true);
            let planForComparison: Plan = allPlans.find((plan) => plan.id.includes(selectedPlan));
            onPlanConfirmed(planForComparison);
        }
    }, [isPlanAutoSelected]);

    const planSelectionBoxDesktop: JSX.Element = (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="434px"
            width="591px"
            sx={{ background: '#F7F1FD', borderRadius: '8px', p: 3 }}
        >
            <Box display="flex" flexDirection="row" alignItems="baseline">
                <Typography component="span" fontSize="1.75rem">
                    {planSelectionInfo.planSelectionTitle}
                </Typography>
            </Box>
            <Box display="flex" flexDirection="row" mb={2}>
                <Typography fontSize="1.125rem">{planSelectionInfo.planSelectionDescription}</Typography>
            </Box>
            <FormControl>
                <StyledSelect
                    value={selectedDevice}
                    defaultValue=""
                    placeholder="Select a Device"
                    onChange={handleDeviceChange}
                    displayEmpty={true}
                    MenuProps={MenuProps}
                    color="primary"
                    sx={{
                        color: selectedDevice === '' ? 'text.disabled' : 'black',
                        '&:before': {
                            borderColor: 'primary',
                        },
                        '&:after': {
                            borderColor: 'primary',
                        },
                        mb: 1,
                    }}
                >
                    <MenuItem value="" sx={{ color: 'text.disabled' }}>
                        Select your phone model
                    </MenuItem>
                    {availableDevices.map((device, index) => (
                        <MenuItem key={index + '-' + device.deviceTitle} value={device.id}>
                            {device.deviceTitle}
                        </MenuItem>
                    ))}
                </StyledSelect>
            </FormControl>
            <Typography fontSize="1.5rem" my={1}>
                {planSelectionInfo.planSelectionInstruction}
            </Typography>
            <FormControl>
                <StyledSelect
                    value={selectedPlan}
                    defaultValue=""
                    onChange={handlePlanChange}
                    disabled={loadingPlans}
                    displayEmpty={true}
                    MenuProps={MenuProps}
                    sx={{
                        color: selectedPlan === '' ? '#979797' : 'black',
                    }}
                >
                    <MenuItem value="" sx={{ color: '#979797' }}>
                        I don't have one
                    </MenuItem>
                    {!loadingPlans &&
                        availablePlans.map((plan, index) => (
                            <MenuItem key={index + '-' + plan.name} value={plan.id}>
                                {plan.name}
                            </MenuItem>
                        ))}
                </StyledSelect>
            </FormControl>

            {selectedPlan === '' && isPlanInputDirty ? (
                <Button
                    component={Link}
                    href={selectedDevice !== '' && selectedPlan === '' && redirectWithParams(`/supported-phones`)}
                    onClick={() => pushToDataLayer('Home_DL_Event SNS Select Device No Plan', 'lite')}
                    variant="contained"
                    disabled={selectedDevice === ''}
                    id="btn-home-sns-purchase-lite-plan"
                    sx={{ mt: 2, mb: 1, padding: '16px 24px', textTransform: 'none' }}
                >
                    Purchase Lite Plan
                </Button>
            ) : (
                <Button
                    component={Link}
                    variant="contained"
                    disabled={selectedDevice === ''}
                    onClick={comparePlans}
                    id="btn-home-sns-plan-comparison"
                    sx={{ mt: 2, mb: 1, padding: '16px 24px', textTransform: 'none' }}
                    ref={comparePlansButtonRef}
                >
                    {planSelectionInfo.ctaButtonText}
                </Button>
            )}
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                sx={{
                    backgroundColor: '#EAD1FE',
                    borderRadius: '8px',
                    mt: 1,
                }}
            ></Box>
        </Box>
    );

    const planSelectionBoxTablet: JSX.Element = (
        <Box
            display="flex"
            flexDirection="column"
            height="374px"
            width="343px"
            sx={{ background: '#F7F1FD', borderRadius: '8px', p: 3 }}
        >
            <Box display="flex" flexDirection="row" alignItems="baseline" mb={1}>
                <Typography fontSize="1.5rem">{planSelectionInfo.planSelectionTitle}</Typography>
            </Box>
            <Box display="flex" flexDirection="row" mb={1}>
                <Typography fontSize="1rem"> {planSelectionInfo.planSelectionDescription}</Typography>
            </Box>

            <FormControl>
                <StyledSelect
                    value={selectedDevice}
                    defaultValue=""
                    placeholder="Select a Device"
                    onChange={handleDeviceChange}
                    displayEmpty={true}
                    MenuProps={MenuPropsMobile}
                    color="primary"
                    sx={{
                        marginBottom: 1,
                        color: selectedDevice === '' ? 'text.disabled' : 'black',
                        '&:before': {
                            borderColor: 'primary',
                        },
                        '&:after': {
                            borderColor: 'primary',
                        },
                    }}
                >
                    <MenuItem value="" sx={{ color: 'text.disabled' }}>
                        Select your phone model
                    </MenuItem>
                    {availableDevices.map((device, index) => (
                        <MenuItem key={index + '-' + device.deviceTitle} value={device.id}>
                            {device.deviceTitle}
                        </MenuItem>
                    ))}
                </StyledSelect>
            </FormControl>

            <Typography fontSize="1rem" my={1}>
                {planSelectionInfo.planSelectionInstruction}
            </Typography>
            <FormControl>
                <StyledSelect
                    value={selectedPlan}
                    defaultValue=""
                    onChange={handlePlanChange}
                    disabled={loadingPlans}
                    displayEmpty={true}
                    MenuProps={MenuProps}
                    sx={{
                        color: selectedPlan === '' ? '#979797' : 'black',
                    }}
                >
                    <MenuItem value="" sx={{ color: '#979797' }}>
                        I don't have one
                    </MenuItem>
                    {!loadingPlans &&
                        availablePlans.map((plan, index) => (
                            <MenuItem key={index + '-' + plan.name} value={plan.id}>
                                {plan.name}
                            </MenuItem>
                        ))}
                </StyledSelect>
            </FormControl>

            {/* <Button
                variant="contained"
                disabled={selectedDevice === ''}
                onClick={comparePlans}
                id="btn-home-sns-plan-comparison"
                sx={{ mt: 2, mb: 1, textTransform: 'none', borderRadius: '8px' }}
            >
                {selectedPlan === '' && isPlanInputDirty ? `Purchase Lite Plan` : `${planSelectionInfo.ctaButtonText}`}
            </Button> */}

            {selectedPlan === '' && isPlanInputDirty ? (
                <Button
                    variant="contained"
                    href={selectedDevice !== '' && selectedPlan === '' && redirectWithParams(`/supported-phones`)}
                    disabled={selectedDevice === ''}
                    onClick={() => pushToDataLayer('Home_DL_Event SNS Select Device No Plan', 'lite')}
                    id="btn-home-sns-purchase-lite-plan"
                    sx={{ mt: 2, mb: 1, textTransform: 'none', borderRadius: '8px' }}
                >
                    Purchase Lite Plan
                </Button>
            ) : (
                <Button
                    variant="contained"
                    disabled={selectedDevice === ''}
                    onClick={comparePlans}
                    id="btn-home-sns-plan-comparison"
                    sx={{ mt: 2, mb: 1, textTransform: 'none', borderRadius: '8px' }}
                    ref={comparePlansButtonRef}
                >
                    {planSelectionInfo.ctaButtonText}
                </Button>
            )}

            <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                sx={{
                    backgroundColor: '#EAD1FE',
                    borderRadius: '8px',
                }}
            ></Box>
        </Box>
    );

    return (
        <Grid
            height={isMobile ? '65%' : '75%'}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent={isMobile && 'center'}
            item
            container
        >
            {isSmall ? planSelectionBoxTablet : planSelectionBoxDesktop}
        </Grid>
    );
}

export default PlanSelection;
