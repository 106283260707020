import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Drawer, Typography, Grid, useMediaQuery } from '@mui/material';
import { graphql } from 'gatsby';
import get from 'lodash/get';

import Seo from '../components/SeoComponent';
import Layout from '../components/LayoutComponent';
import HeroContainer, { HeroContainerDetails } from '../components/save/HeroContainer';
import PlanSelection, { PlanSelectionDetails, Device, Plan, PhonePricing } from '../components/save/PlanSelection';
import Features, { FeatureDetails } from '../components/save/Features';
import { ToastbarDetails } from '../components/ToastBar';
import FAQSave from '../components/save/FAQSave';
import SpinnerOverlay from '../components/common/SpinnerOverlay';
import PlanComparison from '../components/save/PlanComparison';
import { grey } from '@mui/material/colors';
import { styled, useTheme } from '@mui/material/styles';
import { Document as ContentfulDocument } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { options } from '../utils/Utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export const StyledButton = styled(Button)({
    '&': {
        background: '#FFFFFF',
        color: '#671CA6',
        borderBottomLeftRadius: '0',
        borderBottomRightRadius: '0',
        borderBottom: 'none',
        textTransform: 'none',
    },
    '& > svg': {
        color: '#000000',
    },
    '&:hover': {
        background: '#FFFFFF',
        color: '#671CA6',
    },
});

function Save({ data, location }) {
    const theme = useTheme();

    const [pageLoading, setPageLoading] = useState<boolean>(true);
    const [deviceForComparison, setDeviceForComparison] = useState<Device>();
    const [planForComparison, setPlanForComparison] = useState<Plan>();
    const [asurionPlans, setAsurionPlans] = useState<Plan[]>();
    const [asurionPlanForComparison, setAsurionPlanForComparison] = useState<Plan>();
    const [asurionDevicePhonePricing, setAsurionDevicePhonePricing] = useState<PhonePricing>();
    const [filteredPlans, setFilteredPlans] = useState<Plan[]>();
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);
    const [showFaqs, setShowFaqs] = useState<boolean>(false);
    const isSmall: boolean = useMediaQuery(theme.breakpoints.down('md'));
    const topRef = useRef(null);
    const comparisonRef = useRef(null);
    const cancelRef = useRef(null);
    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
    const executeScrollTop = () => scrollToRef(topRef);
    const executeScrollComparison = () => scrollToRef(comparisonRef);
    const executeScrollCancel = () => scrollToRef(cancelRef);

    const legalDisclaimerText: ContentfulDocument = get(data, 'contentfulD2CSaveAndSwitch.legalDisclaimerText');

    const toastBarInfo: ToastbarDetails = {
        toastBarText: get(data, 'contentfulD2CHomeGatsbyHomepage.toastBarText'),
        toastBarBackgroundColor: get(data, 'contentfulD2CHomeGatsbyHomepage.toastBarBackgroundColor'),
        toastBarEnabled: get(data, 'contentfulD2CHomeGatsbyHomepage.toastBarEnabled'),
    };

    const heroContainerInfo: HeroContainerDetails = {
        backgroundImage: get(data, 'contentfulD2CSaveAndSwitch.backgroundImage'),
        backgroundHeroImage: get(data, 'contentfulD2CSaveAndSwitch.backgroundHeroImage'),
        backgroundHeroImageTablet: get(data, 'contentfulD2CSaveAndSwitch.backgroundHeroImageTablet'),
    };

    const planSelectionInfo: PlanSelectionDetails = {
        devices: get(data, 'contentfulD2CSaveAndSwitch.devices'),
        plans: filteredPlans,
        featuresTitle: get(data, 'contentfulD2CSaveAndSwitch.featuresTitle'),
        planSelectionTitle: get(data, 'contentfulD2CSaveAndSwitch.planSelectionTitle'),
        planSelectionDescription: get(data, 'contentfulD2CSaveAndSwitch.planSelectionDescription'),
        planSelectionInstruction: get(data, 'contentfulD2CSaveAndSwitch.planSelectionInstruction'),
        ctaButtonText: get(data, 'contentfulD2CSaveAndSwitch.ctaButtonText'),
    };

    const featuresInfo: FeatureDetails = {
        title: get(data, 'contentfulD2CSaveAndSwitch.featuresTitle'),
        features: get(data, 'contentfulD2CSaveAndSwitch.featureList'),
    };

    const faqTitle = get(data, 'contentfulD2CSaveAndSwitch.faqTitle');

    const planfaqTitle = planForComparison && planForComparison.faqTitle ? planForComparison.faqTitle : '';

    const filterPlans = (plans: Plan[]) => {
        //removes plans with null phone pricing
        let filteredPlans: Plan[] = plans.filter((plan) => plan.phonePricing && !plan.company.includes('Asurion'));
        //gets Asurion plans
        let asurionPlans: Plan[] = plans.filter((plan) => plan.company.includes('Asurion'));
        setAsurionPlans(asurionPlans);
        setFilteredPlans(filteredPlans);
    };

    const allTitles = {
        planSelectionPricingBasisText: get(data, 'contentfulD2CSaveAndSwitch.planSelectionPricingBasisText'),
        classicPlanFsgpdsText: get(data, 'contentfulD2CSaveAndSwitch.classicPlanFsgpdsText'),
        classicPlanFsgpdsLink: get(data, 'contentfulD2CSaveAndSwitch.classicPlanFsgpdsLink'),
        prestigePlanFsgpdsText: get(data, 'contentfulD2CSaveAndSwitch.prestigePlanFsgpdsText'),
        prestigePlanFsgpdsLink: get(data, 'contentfulD2CSaveAndSwitch.prestigePlanFsgpdsLink'),
        insuranceTitle: get(data, 'contentfulD2CSaveAndSwitch.insuranceTitle'),
        annualPriceTitle: get(data, 'contentfulD2CSaveAndSwitch.annualPriceTitle'),
        monthlyPriceTitle: get(data, 'contentfulD2CSaveAndSwitch.monthlyPriceTitle'),
        phonePurchaseTitle: get(data, 'contentfulD2CSaveAndSwitch.phonePurchaseTitle'),
        olderPhonesTitle: get(data, 'contentfulD2CSaveAndSwitch.olderPhonesTitle'),
        renewalTitle: get(data, 'contentfulD2CSaveAndSwitch.renewalTitle'),
        cancelAnytimeTitle: get(data, 'contentfulD2CSaveAndSwitch.cancelAnytimeTitle'),
        totalClaimTitle: get(data, 'contentfulD2CSaveAndSwitch.totalClaimTitle'),
        screenRepairTitle: get(data, 'contentfulD2CSaveAndSwitch.screenRepairTitle'),
        damageReplacementUpgradeTitle: get(data, 'contentfulD2CSaveAndSwitch.damageReplacementUpgradeTitle'),
        lostOrStolenReplacementTitle: get(data, 'contentfulD2CSaveAndSwitch.lostOrStolenReplacementTitle'),
        noClaimsBenefitTitle: get(data, 'contentfulD2CSaveAndSwitch.noClaimsBenefitTitle'),
        additionalExtraTitle: get(data, 'contentfulD2CSaveAndSwitch.additionalExtraTitle'),
        comparePlanText: get(data, 'contentfulD2CSaveAndSwitch.comparePlanText'),
        legalDisclaimerText: legalDisclaimerText,
        serviceProvidedTitle: get(data, 'contentfulD2CSaveAndSwitch.serviceProvidedTitle'),
        serviceProvidedRemark1: get(data, 'contentfulD2CSaveAndSwitch.serviceProvidedRemark1'),
        serviceProvidedRemark2: get(data, 'contentfulD2CSaveAndSwitch.serviceProvidedRemark2'),
        serviceProvidedRemark3: get(data, 'contentfulD2CSaveAndSwitch.serviceProvidedRemark3'),
    };

    const remarkIcons = {
        checkRemarkIcon: get(data, 'contentfulD2CSaveAndSwitch.checkRemarkIcon'),
        crossRemarkIcon: get(data, 'contentfulD2CSaveAndSwitch.crossRemarkIcon'),
        noneRemarkIcon: get(data, 'contentfulD2CSaveAndSwitch.noneRemarkIcon'),
    };
    const policyDescription = get(data, 'contentfulD2CSaveAndSwitch.policyDescription');

    useEffect(() => {
        let allPlans: Plan[] = get(data, 'contentfulD2CSaveAndSwitch.plan');
        filterPlans(allPlans);
        setTimeout(() => {
            setPageLoading(false);
        }, 800);
    }, [data]);

    useEffect(() => {
        //gets asurion plan for comparison to competition
        if (typeof planForComparison !== 'undefined') {
            if (typeof deviceForComparison !== 'undefined') {
                let asurionPlanForComparison: Plan = asurionPlans.find((plan) =>
                    plan.name.includes(planForComparison.asurionPlanComparison[0])
                );

                setAsurionPlanForComparison(asurionPlanForComparison);
                let asurionDeviceForComparison: PhonePricing = asurionPlanForComparison.phonePricing.find((pricing) =>
                    pricing.deviceName.id.includes(deviceForComparison.id)
                );

                setAsurionDevicePhonePricing(asurionDeviceForComparison);
                executeScrollComparison();
            }
            setShowFaqs(true);
        }
    }, [planForComparison, deviceForComparison]);

    const toggleDrawer = (newOpen: boolean) => () => {
        setIsDrawerOpen(newOpen);
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.location.href = '/';
        }
    }, []);

    return null;

    return (
        <>
            <Seo
                title="Switch to Asurion Mobile+"
                description="Asurion Mobile+ covers your phone in the event of accidental damage, loss or theft. Insure your phone based on the level of coverage you want."
                ogTitle="Asurion Mobile+ Smartphone Mobile Phone Insurance"
                ogDescription="Asurion Mobile+ covers your phone in the event of accidental damage, loss or theft. Insure your phone based on the level of coverage you want."
                // canonical={process.env.GATSBY_DOMAIN_URL || '/'}
                canonical={'/'}
            />

            <Layout
                location={location}
                toastBarInfo={toastBarInfo}
                subcomponent={
                    <>
                        <Box id="top" ref={topRef}></Box>
                        {pageLoading && <SpinnerOverlay />}
                        <HeroContainer heroContainerDetails={heroContainerInfo}>
                            <PlanSelection
                                planSelectionInfo={planSelectionInfo}
                                onDeviceConfirmed={setDeviceForComparison}
                                onPlanConfirmed={setPlanForComparison}
                                setShowFaqs={setShowFaqs}
                            />
                            <Features featureDetails={featuresInfo} />
                            <Box id="comparison" ref={comparisonRef}></Box>
                        </HeroContainer>
                        <Box>
                            {typeof planForComparison !== 'undefined' && typeof deviceForComparison !== 'undefined' && (
                                <>
                                    <PlanComparison
                                        planData={planForComparison}
                                        deviceData={deviceForComparison}
                                        asurionPlanData={asurionPlanForComparison}
                                        asurionDevicePhonePricingData={asurionDevicePhonePricing}
                                        executeScrollCancel={executeScrollCancel}
                                        allTitles={allTitles}
                                        remarkIcons={remarkIcons}
                                        policyDescription={policyDescription}
                                        setPlanForComparison={setPlanForComparison}
                                        setShowFaqs={setShowFaqs}
                                    />
                                </>
                            )}
                            {showFaqs && (
                                <>
                                    <Box ref={cancelRef} id="how-to-cancel">
                                        <FAQSave
                                            faqData={planForComparison.howToCancelFaqs}
                                            faqTitle={planfaqTitle}
                                            faqType="plan"
                                        />
                                    </Box>
                                    <FAQSave
                                        faqData={planForComparison.planSpecificFaqs}
                                        faqTitle={faqTitle}
                                        faqType=""
                                    />
                                </>
                            )}
                        </Box>
                    </>
                }
            />
            {isSmall && (
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    sx={{
                        position: 'sticky',
                        bottom: 0,
                        zIndex: 5,
                    }}
                >
                    <StyledButton
                        onClick={() => setIsDrawerOpen(true)}
                        variant="contained"
                        endIcon={<ExpandMoreIcon sx={{ ml: 1, color: '#000000' }} />}
                        sx={{ fontWeight: 'bold' }}
                    >
                        Legal
                    </StyledButton>
                    <Drawer
                        anchor="bottom"
                        open={isDrawerOpen}
                        onClose={toggleDrawer(false)}
                        ModalProps={{
                            keepMounted: true,
                        }}
                        PaperProps={{
                            sx: { background: 'none !important' },
                        }}
                    >
                        <Grid>
                            <Box display="flex" flexDirection="row" justifyContent="center">
                                <StyledButton
                                    onClick={() => setIsDrawerOpen(false)}
                                    variant="contained"
                                    disableElevation
                                    sx={{ fontWeight: 'bold' }}
                                    endIcon={<ExpandLessIcon sx={{ ml: 1, color: '#000000' }} />}
                                >
                                    Legal
                                </StyledButton>
                            </Box>
                            <Box sx={{ background: 'white' }}>
                                <Typography
                                    sx={{
                                        p: 2,
                                        fontSize: '0.75rem',
                                        '& >p>span': { fontSize: '0.75rem', lineHeight: '16px' },
                                        '& >p>p': { fontSize: '0.75rem', lineHeight: '16px' },
                                    }}
                                >
                                    {renderRichText(legalDisclaimerText, options)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Drawer>
                </Box>
            )}
        </>
    );
}
export default Save;

export const pageQuery = graphql`
    query SaveAndSwitchQuery1 {
        contentfulD2CSaveAndSwitch {
            backgroundImage {
                gatsbyImageData(placeholder: NONE, formats: [WEBP], quality: 70)
            }
            backgroundHeroImage {
                gatsbyImageData(placeholder: NONE, formats: [WEBP], quality: 70)
            }
            backgroundHeroImageTablet {
                gatsbyImageData(placeholder: NONE, formats: [WEBP], quality: 70)
            }
            devices {
                id
                modelId
                deviceTitle
                deviceSlug
                asurionLitePlanMonthlyPrice
                asurionLitePlanYearlyPrice
                viewportWidth
                viewportHeight
            }
            faqTitle
            featuresTitle
            featureList {
                title
                description {
                    raw
                }
                icon {
                    file {
                        url
                    }
                }
            }
            features {
                icon
                title
                details
                finePrint
            }
            featuresIcon {
                title
                file {
                    url
                }
            }
            planSelectionTitle
            planSelectionDescription
            planSelectionInstruction
            legalDisclaimerText {
                raw
            }
            ctaButtonText
            comparePlanText
            planSelectionPricingBasisText
            classicPlanFsgpdsText
            classicPlanFsgpdsLink
            prestigePlanFsgpdsText
            prestigePlanFsgpdsLink
            checkRemarkIcon {
                file {
                    url
                }
            }
            crossRemarkIcon {
                file {
                    url
                }
            }
            noneRemarkIcon {
                file {
                    url
                }
            }
            insuranceTitle
            annualPriceTitle
            monthlyPriceTitle
            phonePurchaseTitle
            olderPhonesTitle
            renewalTitle
            cancelAnytimeTitle
            totalClaimTitle
            screenRepairTitle
            damageReplacementUpgradeTitle
            lostOrStolenReplacementTitle
            noClaimsBenefitTitle
            additionalExtraTitle
            serviceProvidedTitle
            serviceProvidedRemark1
            serviceProvidedRemark2
            serviceProvidedRemark3
            policyDescription {
                raw
            }
            plan {
                id
                company
                name
                autoSelectUrlPath
                phonePricing {
                    deviceName {
                        id
                        deviceTitle
                    }
                    monthlyPrice
                    annualPrice
                    biannualPrice
                }
                asurionPlanComparison
                annualPremiumOptionDescription {
                    raw
                }
                annualPremiumOptionExpanderText {
                    raw
                }
                breakdownText
                insuranceRemark
                insuranceDetails
                phonePurchaseDetails
                phonePurchaseRemark
                phonePurchaseExpanderText
                olderPhonesExpanderText
                olderPhonesDetails
                olderPhonesRemark
                changePhoneOrPlanDetails
                changePhoneOrPlanExpanderText
                changePhoneOrPlanRemark
                cancelAnytimeDetails
                cancelAnytimeDescription {
                    raw
                }
                cancelAnytimeExpanderText
                cancelAnytimeRemark
                breakdownText
                faqTitle
                howToCancelFaqs {
                    answer {
                        raw
                    }
                    question
                }
                planSpecificFaqs {
                    question
                    answer {
                        raw
                    }
                }
                screenPhoneRepairDetails
                screenPhoneRepairRemark
                screenPhoneRepairDescription {
                    raw
                }
                screenPhoneRepairExpanderText
                damageReplacementDetails
                damageReplacementRemark
                damageReplacementDescription {
                    raw
                }
                lostOrStolenDetails
                lostOrStolenRemark
                lostOrStolenDescription {
                    raw
                }
                lostOrStolenExpanderText
                noClaimsDetails
                noClaimsRemark
                noClaimsExpanderText
                contractPeriod
                addedExtras {
                    raw
                }
                additionalNotes {
                    raw
                }
            }
        }
        contentfulD2CHomeGatsbyHomepage {
            toastBarText {
                raw
            }
            toastBarBackgroundColor
            toastBarEnabled
        }
    }
`;
