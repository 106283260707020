import React, { memo, useEffect, useState } from 'react';
import {
    Container,
    Box,
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Link as MuiLink,
    useMediaQuery,
    Button,
    Link,
    Snackbar,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme, styled } from '@mui/material/styles';
import { BLOCKS, MARKS, INLINES, Document as ContentfulDocument } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { redirectWithParams, options, checkCookieName } from '../../utils/Utils';
import { PhonePricing, Plan } from './PlanSelection';

import checkIcon from '../../images/Check.png';
import crossIcon from '../../images/cross.png';
import plusIcon from '../../images/White.png';
import closeIcon from '../../images/closec.png';
import CheckSvg from '../../assets/checksvg.svg';
import CrossSvg from '../../assets/crosssvg.svg';

const CustomExpandIcon = () => {
    return (
        <Box
            sx={{
                '.Mui-expanded & > .collapsIconWrapper': {
                    display: 'none',
                },
                '.expandIconWrapper': {
                    display: 'none',
                },
                '.Mui-expanded & > .expandIconWrapper': {
                    display: 'block',
                },
            }}
        >
            <div className="expandIconWrapper">
                <img src={closeIcon} alt="checkicon" />
            </div>

            <div className="collapsIconWrapper">
                <img src={plusIcon} alt="checkicon" />
            </div>
        </Box>
    );
};

const StyledGrid = styled(Grid)({
    display: 'flex',
    flexDirection: 'row',
    background: 'white',
});
const StyledGridService = styled(Grid)({
    display: 'flex',
    flexDirection: 'row',
});
const StyledGridServiceDesktop = styled(Grid)({
    display: 'flex',
    flexDirection: 'row',
    margin: '0 24%',
});

const StyledGridTop = styled(StyledGrid)({
    border: '3px solid #8223D2',
    borderBottom: '0',
});

const StyledGridBottom = styled(StyledGrid)({
    border: '3px solid #8223D2',
    borderTop: '0',
});

const StyledGridMiddle = styled(StyledGrid)({
    border: '3px solid #8223D2',
    borderTop: '0',
    borderBottom: '0',
});

const PlanComparison = ({
    planData,
    deviceData,
    asurionPlanData,
    asurionDevicePhonePricingData,
    executeScrollCancel,
    allTitles,
    remarkIcons,
    policyDescription,
    setPlanForComparison,
    setShowFaqs,
}) => {
    const [show, setShow] = useState(false);
    const [showRepair, setShowRepair] = useState(false);
    const [showDamage, setShowDamage] = useState(false);
    const [showLost, setShowLost] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    const [showAnnual, setShowAnnual] = useState(false);
    const [showPurchase, setShowPurchase] = useState(false);
    const [showOlder, setShowOlder] = useState(false);

    const [isBiannual, setIsBiannual] = useState<boolean>(false);
    const [monthlyPrice, setMonthlyPrice] = useState<string>('');
    const [annualPrice, setAnnualPrice] = useState<string>('');
    const [biannualPrice, setBiannualPrice] = useState<string>('');
    const [annualPriceText, setAnnualPriceText] = useState<string>('');

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isBrowser = typeof window !== 'undefined';

    const asurionPlanComparison: Plan = asurionPlanData ? asurionPlanData : '';
    const asurionDevicePhonePricing: PhonePricing = asurionDevicePhonePricingData ? asurionDevicePhonePricingData : '';

    const checkImg = (
        <Box width="24px">
            <Box component="img" src={remarkIcons.checkRemarkIcon.file.url} alt="check-icon" />
        </Box>
    );
    const crossImg = (
        <Box width="24px">
            <Box component="img" src={remarkIcons.crossRemarkIcon.file.url} alt="cross-icon" />
        </Box>
    );
    const warningImg = (
        <Box width="24px">
            <Box component="img" src={remarkIcons.noneRemarkIcon.file.url} alt="null-icon" />
        </Box>
    );

    const checkImgSmall = (
        <Box width="20px">
            <Box component="img" src={remarkIcons.checkRemarkIcon.file.url} alt="check-icon" width="20px" />
        </Box>
    );
    const crossImgSmall = (
        <Box width="20px">
            <Box component="img" src={remarkIcons.crossRemarkIcon.file.url} alt="cross-icon" width="20px" />
        </Box>
    );
    const warningImgSmall = (
        <Box width="20px">
            <Box component="img" src={remarkIcons.noneRemarkIcon.file.url} alt="null-icon" width="20px" />
        </Box>
    );

    const company = planData && planData.company ? planData.company : '';
    const otherName = planData && planData.name ? planData.name : '';
    const asurionPlanName = planData && planData.asurionPlanComparison ? planData.asurionPlanComparison[0] : '';
    const deviceTitle = deviceData && deviceData.deviceTitle ? deviceData.deviceTitle : '';
    const asurionLitePlanMonthlyPrice =
        deviceData && deviceData.asurionLitePlanMonthlyPrice ? deviceData.asurionLitePlanMonthlyPrice : '';
    const phonePricing = planData && planData.phonePricing ? planData.phonePricing : [];
    const insuranceRemark = planData && planData.insuranceRemark ? planData.insuranceRemark : '';
    const insuranceDetails = planData && planData.insuranceDetails ? planData.insuranceDetails : '';
    const phonePurchaseRemark = planData && planData.phonePurchaseRemark ? planData.phonePurchaseRemark : '';
    const phonePurchaseDetails = planData && planData.phonePurchaseDetails ? planData.phonePurchaseDetails : '';
    const olderPhonesRemark = planData && planData.olderPhonesRemark ? planData.olderPhonesRemark : '';
    const olderPhonesDetails = planData && planData.olderPhonesDetails ? planData.olderPhonesDetails : '';
    const olderPhonesDescription = planData && planData.olderPhonesDescription ? planData.olderPhonesDescription : '';
    const changePhoneOrPlanRemark =
        planData && planData.changePhoneOrPlanRemark ? planData.changePhoneOrPlanRemark : '';
    const changePhoneOrPlanDetails =
        planData && planData.changePhoneOrPlanDetails ? planData.changePhoneOrPlanDetails : '';
    const changePhoneOrPlanExpanderText =
        planData && planData.changePhoneOrPlanExpanderText ? planData.changePhoneOrPlanExpanderText : '';
    const cancelAnytimeRemark = planData && planData.cancelAnytimeRemark ? planData.cancelAnytimeRemark : '';
    const cancelAnytimeDetails = planData && planData.cancelAnytimeDetails ? planData.cancelAnytimeDetails : '';
    const cancelAnytimeExpanderText =
        planData && planData.cancelAnytimeExpanderText ? planData.cancelAnytimeExpanderText : '';
    const cancelAnytimeDescription =
        planData && planData.cancelAnytimeDescription ? planData.cancelAnytimeDescription : '';

    const screenPhoneRepairRemark =
        planData && planData.screenPhoneRepairRemark ? planData.screenPhoneRepairRemark : '';
    const screenPhoneRepairDetails =
        planData && planData.screenPhoneRepairDetails ? planData.screenPhoneRepairDetails : '';
    const screenPhoneRepairDescription =
        planData && planData.screenPhoneRepairDescription ? planData.screenPhoneRepairDescription : '';
    const damageReplacementRemark =
        planData && planData.damageReplacementRemark ? planData.damageReplacementRemark : '';
    const damageReplacementDetails =
        planData && planData.damageReplacementDetails ? planData.damageReplacementDetails : '';
    const damageReplacementDescription =
        planData && planData.damageReplacementDescription ? planData.damageReplacementDescription : '';
    const lostOrStolenRemark = planData && planData.lostOrStolenRemark ? planData.lostOrStolenRemark : '';
    const lostOrStolenDetails = planData && planData.lostOrStolenDetails ? planData.lostOrStolenDetails : '';
    const lostOrStolenDescription =
        planData && planData.lostOrStolenDescription ? planData.lostOrStolenDescription : '';
    const lostOrStolenExpanderText =
        planData && planData.lostOrStolenExpanderText ? planData.lostOrStolenExpanderText : '';
    const noClaimsRemark = planData && planData.noClaimsRemark ? planData.noClaimsRemark : '';
    const noClaimsDetails = planData && planData.noClaimsDetails ? planData.noClaimsDetails : '';
    const noClaimsExpanderText = planData && planData.noClaimsExpanderText ? planData.noClaimsExpanderText : '';
    const addedExtras = planData && planData.addedExtras ? planData.addedExtras : '';
    const breakdownText = planData && planData.breakdownText ? planData.breakdownText : '';
    const annualPremiumOptionDescription =
        planData && planData.annualPremiumOptionDescription ? planData.annualPremiumOptionDescription : '';
    const annualPremiumOptionExpanderText =
        planData && planData.annualPremiumOptionExpanderText ? planData.annualPremiumOptionExpanderText : '';
    const phonePurchaseExpanderText =
        planData && planData.phonePurchaseExpanderText ? planData.phonePurchaseExpanderText : '';
    const olderPhonesExpanderText =
        planData && planData.olderPhonesExpanderText ? planData.olderPhonesExpanderText : '';

    const StyledTypography = styled(Typography)({
        marginLeft: '8px',
        fontSize: isSmall ? '1rem' : '1.125rem',
        '& > p': {
            fontSize: isSmall ? '1rem' : '1.125rem',
        },
    });

    const StyledTypographyCaption = styled(Typography)({
        marginLeft: '8px',
        fontSize: isSmall ? '0.75rem !important' : '1rem !important',
        '& > p': {
            fontSize: isSmall ? '0.75rem !important' : '1rem !important',
        },
    });
    const StyledTypographyDisabled = styled(StyledTypographyCaption)({
        color: '#979797',
        '& > p > span': {
            color: '#979797',
        },
    });

    const StyledTypographyGreen = styled(StyledTypographyCaption)({
        color: '#046840',
        '& > p > span': {
            color: '#046840',
        },
    });

    const StyledTypographyRed = styled(StyledTypographyCaption)({
        color: '#D91B1B',
        '& > p > span': {
            color: '#D91B1B',
        },
    });

    const showRemarkIcon = (value: string | boolean) => {
        if (value === '' || value === null) {
            return warningImg;
        } else {
            if (value === true) {
                return checkImg;
            } else {
                return crossImg;
            }
        }
    };

    useEffect(() => {
        phonePricing.find(function (post, index) {
            if (post.deviceName.deviceTitle == deviceTitle) {
                setMonthlyPrice(post.monthlyPrice);
                if (post.annualPrice !== null && post.annualPrice !== '' && post.annualPrice !== ' ') {
                    setAnnualPrice(post.annualPrice);
                    setAnnualPriceText('/yr');
                    setIsBiannual(false);
                } else if (post.biannualPrice !== null && post.biannualPrice !== '' && post.biannualPrice !== ' ') {
                    setAnnualPrice(post.biannualPrice);
                    setAnnualPriceText('/yr (2 yr contract)');
                    setIsBiannual(true);
                } else {
                    setAnnualPrice(null);
                }
            }
        });

        let competitionPhonePricing: PhonePricing = phonePricing.find((pricing) =>
            pricing.deviceName.deviceTitle.includes(deviceTitle)
        );
    }, [deviceData, planData]);

    const selectPlan = (event) => {
        resetState();
        pushToDataLayer(event);
    };

    const pushToDataLayer = (event: string) => {
        if (isBrowser) {
            window.dataLayer.push({
                event: event,
                GCLID: checkCookieName('gclid') || null,
                GAClientID:
                    (checkCookieName('_ga') &&
                        checkCookieName('_ga').substring(checkCookieName('_ga').indexOf('.') + 3)) ||
                    null,
                UTMParams: {
                    utm_source: checkCookieName('utm_source') || null,
                    utm_content: checkCookieName('utm_content') || null,
                    utm_id: checkCookieName('utm_id') || null,
                    utm_campaign: checkCookieName('utm_campaign') || null,
                    utm_medium: checkCookieName('utm_medium') || null,
                    utm_term: checkCookieName('utm_term') || null,
                },
                DeviceName: deviceTitle || null,
                AsurionPlanName: asurionPlanName || null,
            });
        }
    };

    const toAnnualPrice = (biAnnualPrice: string) => {
        let price: number = parseFloat(biAnnualPrice).toFixed(2) / 2;

        return price;
    };

    const resetState = () => {
        if (isBrowser) {
            window.scrollTo(0, 0);
        }
        setShow(false);
        setShowRepair(false);
        setShowDamage(false);
        setShowLost(false);
        setShowCancel(false);
        setShowAnnual(false);
        setShowPurchase(false);
        setShowOlder(false);

        setIsBiannual(false);
        setMonthlyPrice('');
        setAnnualPrice('');
        setBiannualPrice('');
        setAnnualPriceText('');
        setPlanForComparison();
        setShowFaqs(false);
    };

    const planComparisonSmall: JSX.Element = (
        <>
            <Grid display="flex" flexDirection="column" justifyContent="center" md={4} p={0}>
                <Typography fontSize="1.5rem" textAlign="center">
                    Based on {deviceTitle}. Compare Asurion mobile+ and {otherName}
                </Typography>

                <Typography fontSize="1rem" textAlign="center" p={1}>
                    {allTitles.comparePlanText}
                </Typography>

                {/* <Snackbar
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={transition}
                    message={allTitles.disclaimerText && renderRichText(allTitles.disclaimerText, options)}
                    key={transition ? transition.name : ''}
                /> */}
            </Grid>

            <Grid container sx={{ border: '1px solid #D5D6DA', backgroundColor: '#fff' }}>
                <Grid
                    item
                    xs={6}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    sx={{ border: '3px solid #8223D2', padding: '4%' }}
                >
                    {(monthlyPrice !== null && monthlyPrice !== '' && monthlyPrice !== ' ' && (
                        <Box>
                            <Typography sx={{ fontSize: '24px', color: '#671CA6' }}>Asurion </Typography>
                            <Typography sx={{ color: '#333333' }}>{asurionPlanName} Plan </Typography>
                            <Typography component="span" fontSize="24px" fontWeight="bold" color="text.priceGreen">
                                ${asurionDevicePhonePricing.monthlyPrice}*
                            </Typography>
                            <Typography component="span">/mth </Typography>
                        </Box>
                    )) || (
                        <Box>
                            <Typography sx={{ fontSize: '24px', color: '#671CA6' }}>Asurion </Typography>
                            <Typography sx={{ color: '#333333' }}>{asurionPlanName} Plan </Typography>
                            <Typography component="span" fontSize="24px" fontWeight="bold" color="text.priceGreen">
                                ${asurionDevicePhonePricing.annualPrice}*
                            </Typography>
                            <Typography component="span">/yr </Typography>
                        </Box>
                    )}

                    <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                        {asurionPlanName.toLowerCase().includes('classic') ? (
                            <Link
                                href={`${process.env.GATSBY_DOMAIN_URL}${allTitles.classicPlanFsgpdsLink}`}
                                target="_blank"
                                color="secondary"
                                id="btn-home-sns-classic-fsg-pds"
                            >
                                {allTitles.classicPlanFsgpdsText}
                            </Link>
                        ) : (
                            <Link
                                href={`${process.env.GATSBY_DOMAIN_URL}${allTitles.prestigePlanFsgpdsLink}`}
                                target="_blank"
                                color="secondary"
                                id="btn-home-sns-prestige-fsg-pds"
                            >
                                {allTitles.prestigePlanFsgpdsText}
                            </Link>
                        )}
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={6}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    sx={{ padding: '4%' }}
                >
                    <Box>
                        <Typography sx={{ fontSize: '24px', color: '#671CA6' }}>{company} </Typography>
                        <Typography sx={{ color: '#333333' }}>{otherName} </Typography>
                        {(monthlyPrice !== null && monthlyPrice !== '' && monthlyPrice !== ' ' && (
                            <>
                                <Typography component="span" fontSize="24px" fontWeight="bold" color="text.priceRed">
                                    ${monthlyPrice}*
                                </Typography>
                                <Typography component="span">/mth </Typography>
                            </>
                        )) || (
                            <>
                                <Typography component="span" fontSize="24px" fontWeight="bold" color="text.priceRed">
                                    ${isBiannual ? toAnnualPrice(annualPrice) : annualPrice}*
                                </Typography>
                                <Typography component="span">{annualPriceText}</Typography>
                            </>
                        )}
                    </Box>
                    <Box>
                        <Button
                            onClick={() => executeScrollCancel()}
                            id="btn-home-sns-how-to-cancel"
                            sx={{
                                textAlign: 'center',
                                border: '1px solid #8223D2',
                                padding: '5%',
                                margin: '5% 0',
                                fontSize: '16px',
                                width: '100%',
                                textTransform: 'none',
                            }}
                        >
                            How to cancel?
                        </Button>

                        <Link href="#" sx={{ opacity: '0' }}>
                            t
                        </Link>
                    </Box>
                </Grid>
            </Grid>
            <Grid flexDirection="column" display="flex" justifyContent="center" textAlign="center" sx={{ m: 2 }}>
                <Typography sx={{ fontSize: isMobile ? '16px' : '2rem' }}>
                    {allTitles.planSelectionPricingBasisText}
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{ padding: '2%', backgroundColor: '#F2F2F2', textAlign: 'center' }}>
                <StyledGridService>
                    <Typography sx={{ fontSize: '12px', textAlign: 'center', fontWeight: 'bold', padding: '2%' }}>
                        {allTitles.serviceProvidedTitle}
                    </Typography>
                    {checkImgSmall}
                    <Typography sx={{ fontSize: '12px', textAlign: 'center', padding: '1%' }}>
                        {allTitles.serviceProvidedRemark1}
                    </Typography>
                    {crossImgSmall}
                    <Typography sx={{ fontSize: '12px', padding: '1%', textAlign: 'center' }}>
                        {allTitles.serviceProvidedRemark2}
                    </Typography>
                    {warningImgSmall}
                    <Typography sx={{ fontSize: '12px', padding: '1%', textAlign: 'center' }}>
                        {allTitles.serviceProvidedRemark3}
                    </Typography>
                </StyledGridService>
            </Grid>

            <Grid container sx={{ backgroundColor: '#fff' }}>
                <Grid item xs={12} sx={{ padding: '4%!important', backgroundColor: '#E3E3E3' }}>
                    <Typography sx={{ fontSize: '16px', color: '#111' }}>{allTitles.insuranceTitle}</Typography>
                </Grid>
                <StyledGridMiddle item xs={6} sx={{ padding: '4%' }}>
                    {checkImg}
                </StyledGridMiddle>
                <StyledGrid item xs={6} sx={{ padding: '4%' }}>
                    {showRemarkIcon(planData.insuranceRemark)}
                    <StyledTypography>{insuranceDetails}</StyledTypography>
                </StyledGrid>
                {(monthlyPrice !== null && monthlyPrice !== '' && monthlyPrice !== ' ' && (
                    <Grid item xs={12} sx={{ padding: '4%', backgroundColor: '#E3E3E3' }}>
                        <Typography sx={{ fontSize: '16px', color: '#111' }}>
                            {allTitles.annualPriceTitle}
                            {showAnnual &&
                                (annualPremiumOptionExpanderText ||
                                    asurionPlanComparison.annualPremiumOptionExpanderText) && (
                                    <div
                                        className="expandIconWrapper"
                                        onClick={() => setShowAnnual((prev) => !prev)}
                                        style={{ float: 'right' }}
                                    >
                                        <img src={closeIcon} alt="checkicon" />
                                    </div>
                                )}
                            {!showAnnual &&
                                (annualPremiumOptionExpanderText ||
                                    asurionPlanComparison.annualPremiumOptionExpanderText) && (
                                    <div
                                        className="collapsIconWrapper"
                                        onClick={() => setShowAnnual((prev) => !prev)}
                                        style={{ float: 'right' }}
                                    >
                                        <img src={plusIcon} alt="checkicon" />
                                    </div>
                                )}{' '}
                        </Typography>
                    </Grid>
                )) || (
                    <Grid item xs={12} sx={{ padding: '4%', backgroundColor: '#E3E3E3' }}>
                        <Typography sx={{ fontSize: '16px', color: '#111' }}>
                            {allTitles.monthlyPriceTitle}
                            {showAnnual &&
                                (annualPremiumOptionExpanderText ||
                                    (asurionPlanData && asurionPlanData.annualPremiumOptionExpanderText)) && (
                                    <div
                                        className="expandIconWrapper"
                                        onClick={() => setShowAnnual((prev) => !prev)}
                                        style={{ float: 'right' }}
                                    >
                                        <img src={closeIcon} alt="checkicon" />
                                    </div>
                                )}
                            {!showAnnual &&
                                (annualPremiumOptionExpanderText ||
                                    (asurionPlanData && asurionPlanData.annualPremiumOptionExpanderText)) && (
                                    <div
                                        className="collapsIconWrapper"
                                        onClick={() => setShowAnnual((prev) => !prev)}
                                        style={{ float: 'right' }}
                                    >
                                        <img src={plusIcon} alt="checkicon" />
                                    </div>
                                )}{' '}
                        </Typography>
                    </Grid>
                )}
                {(monthlyPrice !== null && monthlyPrice !== '' && monthlyPrice !== ' ' && (
                    <StyledGridMiddle item xs={6} sx={{ padding: '4%' }}>
                        {checkImg}
                        <Box width="100%">
                            <StyledTypography color="text.priceGreen" sx={{ fontWeight: '700', float: 'left' }}>
                                ${asurionDevicePhonePricing.annualPrice}
                            </StyledTypography>
                            <StyledTypography sx={{ m: 0 }}>/yr</StyledTypography>
                            {showAnnual && (
                                <StyledTypographyDisabled>
                                    {asurionPlanData.annualPremiumOptionExpanderText &&
                                        renderRichText(asurionPlanData.annualPremiumOptionExpanderText, options)}
                                </StyledTypographyDisabled>
                            )}
                        </Box>
                    </StyledGridMiddle>
                )) || (
                    <StyledGridMiddle item xs={6} sx={{ padding: '4%' }}>
                        {checkImg}
                        <Box width="100%">
                            <StyledTypography color="text.priceGreen" sx={{ fontWeight: '700', float: 'left' }}>
                                ${asurionDevicePhonePricing.monthlyPrice}
                            </StyledTypography>
                            <StyledTypography sx={{ m: 0 }}>/mth</StyledTypography>
                            {showAnnual && (
                                <StyledTypographyDisabled>
                                    {asurionPlanData.annualPremiumOptionExpanderText &&
                                        renderRichText(asurionPlanData.annualPremiumOptionExpanderText, options)}
                                </StyledTypographyDisabled>
                            )}
                        </Box>
                    </StyledGridMiddle>
                )}

                {(monthlyPrice !== null && monthlyPrice !== '' && monthlyPrice !== ' ' && (
                    <StyledGrid item xs={6} sx={{ padding: '4%', display: 'flex' }}>
                        <Box width="100%">
                            {annualPrice !== null && annualPrice !== '' && annualPrice !== ' ' ? (
                                <>
                                    <StyledTypography sx={{ color: 'text.priceRed', fontWeight: '700', float: 'left' }}>
                                        ${isBiannual ? toAnnualPrice(annualPrice) : annualPrice}*
                                    </StyledTypography>
                                    <StyledTypography sx={{ m: 0 }}>{annualPriceText}</StyledTypography>
                                </>
                            ) : (
                                <StyledTypography>None</StyledTypography>
                            )}
                            {showAnnual && (
                                <StyledTypographyDisabled>
                                    {annualPremiumOptionExpanderText &&
                                        renderRichText(annualPremiumOptionExpanderText, options)}
                                </StyledTypographyDisabled>
                            )}
                        </Box>
                    </StyledGrid>
                )) || (
                    <StyledGrid item xs={6} sx={{ padding: '4%', display: 'flex' }}>
                        <Box width="100%">
                            {monthlyPrice !== null && monthlyPrice !== '' && monthlyPrice !== ' ' ? (
                                <>
                                    <StyledTypography sx={{ color: 'text.priceRed', fontWeight: '700', float: 'left' }}>
                                        ${monthlyPrice}
                                    </StyledTypography>
                                    <StyledTypography sx={{ m: 0 }}>/mth</StyledTypography>
                                </>
                            ) : (
                                <StyledTypography>None</StyledTypography>
                            )}
                            {showAnnual && (
                                <StyledTypographyDisabled>
                                    {annualPremiumOptionExpanderText &&
                                        renderRichText(annualPremiumOptionExpanderText, options)}
                                </StyledTypographyDisabled>
                            )}
                        </Box>
                    </StyledGrid>
                )}

                <StyledGridMiddle item xs={6} sx={{}}></StyledGridMiddle>
                <StyledGrid item xs={6} sx={{}}>
                    <StyledTypography>
                        {annualPremiumOptionDescription && renderRichText(annualPremiumOptionDescription, options)}
                    </StyledTypography>
                </StyledGrid>

                <Grid item xs={12} sx={{ padding: '4%', backgroundColor: '#E3E3E3' }}>
                    <Typography sx={{ fontSize: '16px', color: '#111' }}>
                        {allTitles.phonePurchaseTitle}
                        {showPurchase &&
                            (phonePurchaseExpanderText ||
                                (asurionPlanComparison && asurionPlanComparison.phonePurchaseExpanderText)) && (
                                <div
                                    className="expandIconWrapper"
                                    onClick={() => setShowPurchase((prev) => !prev)}
                                    style={{ float: 'right' }}
                                >
                                    <img src={closeIcon} alt="checkicon" />
                                </div>
                            )}
                        {!showPurchase &&
                            (phonePurchaseExpanderText ||
                                (asurionPlanComparison && asurionPlanComparison.phonePurchaseExpanderText)) && (
                                <div
                                    className="collapsIconWrapper"
                                    onClick={() => setShowPurchase((prev) => !prev)}
                                    style={{ float: 'right' }}
                                >
                                    <img src={plusIcon} alt="checkicon" />
                                </div>
                            )}{' '}
                    </Typography>
                </Grid>
                <StyledGridMiddle item xs={6} sx={{ padding: '4%' }}>
                    {checkImg}
                    <StyledTypography>{asurionPlanComparison.phonePurchaseDetails}</StyledTypography>
                    {showPurchase && (
                        <StyledTypographyDisabled>
                            {asurionPlanComparison.phonePurchaseExpanderText}
                        </StyledTypographyDisabled>
                    )}
                </StyledGridMiddle>
                <StyledGrid item xs={6} sx={{ padding: '4%' }}>
                    {showRemarkIcon(planData.phonePurchaseRemark)}
                    <Box>
                        <StyledTypography>{phonePurchaseDetails}</StyledTypography>
                        {showPurchase && (
                            <StyledTypographyDisabled>{phonePurchaseExpanderText}</StyledTypographyDisabled>
                        )}
                    </Box>
                </StyledGrid>
                <Grid item xs={12} sx={{ padding: '4%', backgroundColor: '#E3E3E3' }}>
                    <Typography sx={{ fontSize: '16px', color: '#111' }}>
                        {allTitles.olderPhonesTitle}
                        {showOlder &&
                            (olderPhonesExpanderText ||
                                (asurionPlanComparison && asurionPlanComparison.olderPhonesExpanderText)) && (
                                <div
                                    className="expandIconWrapper"
                                    onClick={() => setShowOlder((prev) => !prev)}
                                    style={{ float: 'right' }}
                                >
                                    <img src={closeIcon} alt="checkicon" />
                                </div>
                            )}
                        {!showOlder &&
                            (olderPhonesExpanderText ||
                                (asurionPlanComparison && asurionPlanComparison.olderPhonesExpanderText)) && (
                                <div
                                    className="collapsIconWrapper"
                                    onClick={() => setShowOlder((prev) => !prev)}
                                    style={{ float: 'right' }}
                                >
                                    <img src={plusIcon} alt="checkicon" />
                                </div>
                            )}
                    </Typography>
                </Grid>
                <StyledGridMiddle item xs={6} sx={{ padding: '4%' }}>
                    {checkImg}
                    <Box width="100%">
                        <StyledTypography>{asurionPlanComparison.olderPhonesDetails}</StyledTypography>
                        <StyledTypographyDisabled>
                            {asurionPlanComparison.olderPhonesDescription &&
                                renderRichText(asurionPlanComparison.olderPhonesDescription, options)}
                        </StyledTypographyDisabled>
                        {showOlder && (
                            <StyledTypographyDisabled>
                                {asurionPlanComparison.olderPhonesExpanderText}
                            </StyledTypographyDisabled>
                        )}
                    </Box>
                </StyledGridMiddle>
                <StyledGrid item xs={6} sx={{ padding: '4%' }}>
                    {showRemarkIcon(planData.olderPhonesRemark)}
                    <Box width="100%">
                        <StyledTypography>{olderPhonesDetails}</StyledTypography>
                        <StyledTypographyDisabled>
                            {olderPhonesDescription && renderRichText(olderPhonesDescription, options)}
                        </StyledTypographyDisabled>
                        {showOlder && <StyledTypographyDisabled>{olderPhonesExpanderText}</StyledTypographyDisabled>}
                    </Box>
                </StyledGrid>

                <Grid item xs={12} sx={{ padding: '4%', backgroundColor: '#E3E3E3' }}>
                    <Typography sx={{ fontSize: '16px', color: '#111' }}>
                        {allTitles.renewalTitle}
                        {show &&
                            (changePhoneOrPlanExpanderText ||
                                (asurionPlanComparison && asurionPlanComparison.changePhoneOrPlanExpanderText)) && (
                                <div
                                    className="expandIconWrapper"
                                    onClick={() => setShow((prev) => !prev)}
                                    style={{ float: 'right' }}
                                >
                                    <img src={closeIcon} alt="checkicon" />
                                </div>
                            )}
                        {!show &&
                            (changePhoneOrPlanExpanderText ||
                                (asurionPlanComparison && asurionPlanComparison.changePhoneOrPlanExpanderText)) && (
                                <div
                                    className="collapsIconWrapper"
                                    onClick={() => setShow((prev) => !prev)}
                                    style={{ float: 'right' }}
                                >
                                    <img src={plusIcon} alt="checkicon" />
                                </div>
                            )}
                    </Typography>
                </Grid>
                <StyledGridMiddle item xs={6} sx={{ borderLeft: '3px solid #8223D2', padding: '4%' }}>
                    {checkImg}
                    <Box width="100%">
                        <StyledTypography>{asurionPlanComparison.changePhoneOrPlanDetails}</StyledTypography>

                        {show && (
                            <StyledTypographyDisabled>
                                {asurionPlanComparison.changePhoneOrPlanExpanderText}
                            </StyledTypographyDisabled>
                        )}
                    </Box>
                </StyledGridMiddle>
                <StyledGrid item xs={6} sx={{ padding: '4%' }}>
                    {showRemarkIcon(planData.changePhoneOrPlanRemark)}
                    <StyledTypography>{changePhoneOrPlanDetails}</StyledTypography>
                    {show && <StyledTypographyDisabled>{changePhoneOrPlanExpanderText}</StyledTypographyDisabled>}
                </StyledGrid>
                <Grid item xs={12} sx={{ padding: '4%', backgroundColor: '#E3E3E3' }}>
                    <Typography sx={{ fontSize: '16px', color: '#111' }}>
                        {allTitles.cancelAnytimeTitle}
                        {showCancel &&
                            (cancelAnytimeExpanderText || asurionPlanComparison.cancelAnytimeExpanderText) && (
                                <div
                                    className="expandIconWrapper"
                                    onClick={() => setShowCancel((prev) => !prev)}
                                    style={{ float: 'right' }}
                                >
                                    <img src={closeIcon} alt="checkicon" />
                                </div>
                            )}
                        {!showCancel &&
                            (cancelAnytimeExpanderText || asurionPlanComparison.cancelAnytimeExpanderText) && (
                                <div
                                    className="collapsIconWrapper"
                                    onClick={() => setShowCancel((prev) => !prev)}
                                    style={{ float: 'right' }}
                                >
                                    <img src={plusIcon} alt="checkicon" />
                                </div>
                            )}
                    </Typography>
                </Grid>
                <StyledGridMiddle item xs={6} sx={{ padding: '4%' }}>
                    {checkImg}
                    <Box width="100%">
                        <StyledTypography>{asurionPlanComparison.cancelAnytimeDetails}</StyledTypography>
                        {showCancel && (
                            <StyledTypographyDisabled>
                                {asurionPlanComparison.cancelAnytimeExpanderText}
                            </StyledTypographyDisabled>
                        )}
                        <StyledTypographyDisabled>
                            {asurionPlanComparison.cancelAnytimeDescription &&
                                renderRichText(asurionPlanComparison.cancelAnytimeDescription, options)}
                        </StyledTypographyDisabled>
                    </Box>
                </StyledGridMiddle>
                <StyledGrid item xs={6} sx={{ padding: '4%' }}>
                    {showRemarkIcon(planData.cancelAnytimeRemark)}
                    <Box width="100%">
                        <StyledTypography>{cancelAnytimeDetails}</StyledTypography>
                        {showCancel && <StyledTypographyDisabled>{cancelAnytimeExpanderText}</StyledTypographyDisabled>}
                        <StyledTypographyDisabled>
                            {cancelAnytimeDescription && renderRichText(cancelAnytimeDescription, options)}
                        </StyledTypographyDisabled>
                    </Box>
                </StyledGrid>
            </Grid>
            <Grid container sx={{ backgroundColor: '#fff' }}>
                <Grid item xs={12} sx={{ padding: '4%', backgroundColor: '#4C147A' }}>
                    <Typography sx={{ fontSize: '18px', color: '#fff' }}>
                        Repair, Damage, Loss & Theft Coverage{' '}
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ padding: '4%', backgroundColor: '#F2F2F2' }}>
                    <Typography sx={{ fontSize: '12px', fontWeight: 'bold', textAlign: 'center' }}>
                        {allTitles.totalClaimTitle}{' '}
                    </Typography>
                </Grid>

                <Grid item xs={6} sx={{ padding: '4%', backgroundColor: '#F2F2F2', textAlign: 'center' }}>
                    <StyledTypographyGreen>{asurionPlanComparison.breakdownText}</StyledTypographyGreen>
                </Grid>

                <Grid item xs={6} sx={{ padding: '4%', backgroundColor: '#F2F2F2', textAlign: 'center' }}>
                    <StyledTypographyRed>{breakdownText}</StyledTypographyRed>
                </Grid>
                <Grid item xs={12} sx={{ padding: '4%', backgroundColor: '#E3E3E3' }}>
                    <Typography sx={{ fontSize: '16px', color: '#111' }}>
                        {allTitles.screenRepairTitle}
                        {showRepair &&
                            (screenPhoneRepairDescription || asurionPlanComparison.screenPhoneRepairDescription) && (
                                <div
                                    className="expandIconWrapper"
                                    onClick={() => setShowRepair((prev) => !prev)}
                                    style={{ float: 'right' }}
                                >
                                    <img src={closeIcon} alt="checkicon" />
                                </div>
                            )}
                        {!showRepair &&
                            (screenPhoneRepairDescription || asurionPlanComparison.screenPhoneRepairDescription) && (
                                <div
                                    className="collapsIconWrapper"
                                    onClick={() => setShowRepair((prev) => !prev)}
                                    style={{ float: 'right' }}
                                >
                                    <img src={plusIcon} alt="checkicon" />
                                </div>
                            )}{' '}
                    </Typography>
                </Grid>
                <StyledGridMiddle item xs={6} sx={{ padding: '4%' }}>
                    {checkImg}
                    <Box width="100%">
                        <StyledTypography>{asurionPlanComparison.screenPhoneRepairDetails}</StyledTypography>
                        <StyledTypographyGreen>
                            {showRepair && (
                                <>
                                    {asurionPlanComparison.screenPhoneRepairDescription &&
                                        renderRichText(asurionPlanComparison.screenPhoneRepairDescription, options)}
                                </>
                            )}
                        </StyledTypographyGreen>
                    </Box>
                </StyledGridMiddle>
                <StyledGrid item xs={6} sx={{ padding: '4%' }}>
                    {showRemarkIcon(planData.screenPhoneRepairRemark)}
                    <Box width="100%">
                        {screenPhoneRepairRemark ? (
                            <StyledTypography>{screenPhoneRepairDetails}</StyledTypography>
                        ) : (
                            <StyledTypography color="text.priceRed">{screenPhoneRepairDetails}</StyledTypography>
                        )}
                        <StyledTypographyGreen>
                            {showRepair && (
                                <>
                                    {screenPhoneRepairDescription &&
                                        renderRichText(screenPhoneRepairDescription, options)}
                                </>
                            )}
                        </StyledTypographyGreen>
                    </Box>
                </StyledGrid>
                <Grid item xs={12} sx={{ padding: '4%', backgroundColor: '#E3E3E3' }}>
                    <Typography sx={{ fontSize: '16px', color: '#111' }}>
                        {allTitles.damageReplacementUpgradeTitle}
                        {showDamage &&
                            (damageReplacementDescription || asurionPlanComparison.damageReplacementDescription) && (
                                <div
                                    className="expandIconWrapper"
                                    onClick={() => setShowDamage((prev) => !prev)}
                                    style={{ float: 'right' }}
                                >
                                    <img src={closeIcon} alt="checkicon" />
                                </div>
                            )}
                        {!showDamage &&
                            (damageReplacementDescription || asurionPlanComparison.damageReplacementDescription) && (
                                <div
                                    className="collapsIconWrapper"
                                    onClick={() => setShowDamage((prev) => !prev)}
                                    style={{ float: 'right' }}
                                >
                                    <img src={plusIcon} alt="checkicon" />
                                </div>
                            )}
                    </Typography>
                </Grid>
                <StyledGridMiddle item xs={6} sx={{ padding: '4%' }}>
                    {checkImg}
                    <Box width="100%">
                        <StyledTypography>{asurionPlanComparison.damageReplacementDetails}</StyledTypography>
                        <StyledTypographyGreen>
                            {showDamage && (
                                <>
                                    {asurionPlanComparison.damageReplacementDescription &&
                                        renderRichText(asurionPlanComparison.damageReplacementDescription, options)}
                                </>
                            )}
                        </StyledTypographyGreen>
                    </Box>
                </StyledGridMiddle>
                <StyledGrid item xs={6} sx={{ padding: '4%' }}>
                    {showRemarkIcon(planData.damageReplacementRemark)}
                    <Box width="100%">
                        {damageReplacementRemark ? (
                            <StyledTypography>{damageReplacementDetails}</StyledTypography>
                        ) : (
                            <StyledTypography color="text.priceRed">{damageReplacementDetails}</StyledTypography>
                        )}
                        <StyledTypographyGreen>
                            {showDamage && (
                                <>
                                    {damageReplacementDescription &&
                                        renderRichText(damageReplacementDescription, options)}
                                </>
                            )}
                        </StyledTypographyGreen>
                    </Box>
                </StyledGrid>
                <Grid item xs={12} sx={{ padding: '4%', backgroundColor: '#E3E3E3' }}>
                    <Typography sx={{ fontSize: '16px', color: '#111' }}>
                        {allTitles.lostOrStolenReplacementTitle}

                        {showLost && (lostOrStolenDescription || asurionPlanComparison.lostOrStolenDescription) && (
                            <div
                                className="expandIconWrapper"
                                onClick={() => setShowLost((prev) => !prev)}
                                style={{ float: 'right' }}
                            >
                                <img src={closeIcon} alt="checkicon" />
                            </div>
                        )}
                        {!showLost && (lostOrStolenDescription || asurionPlanComparison.lostOrStolenDescription) && (
                            <div
                                className="collapsIconWrapper"
                                onClick={() => setShowLost((prev) => !prev)}
                                style={{ float: 'right' }}
                            >
                                <img src={plusIcon} alt="checkicon" />
                            </div>
                        )}
                    </Typography>
                </Grid>
                <StyledGridMiddle item xs={6} sx={{ padding: '4%' }}>
                    {showRemarkIcon(asurionPlanComparison.lostOrStolenRemark)}
                    <Box width="100%">
                        {asurionPlanComparison.lostOrStolenRemark ? (
                            <StyledTypography>{asurionPlanComparison.lostOrStolenDetails}</StyledTypography>
                        ) : (
                            <StyledTypography color="text.priceRed">
                                {asurionPlanComparison.lostOrStolenDetails}
                            </StyledTypography>
                        )}

                        <StyledTypographyGreen>
                            {showLost && (
                                <>
                                    {asurionPlanComparison.lostOrStolenDescription &&
                                        renderRichText(asurionPlanComparison.lostOrStolenDescription, options)}
                                </>
                            )}
                        </StyledTypographyGreen>
                    </Box>
                </StyledGridMiddle>
                <StyledGrid item xs={6} sx={{ padding: '4%' }}>
                    {showRemarkIcon(planData.lostOrStolenRemark)}
                    <Box width="100%">
                        {lostOrStolenRemark ? (
                            <StyledTypography>{lostOrStolenDetails}</StyledTypography>
                        ) : (
                            <StyledTypography color="text.priceRed">{lostOrStolenDetails}</StyledTypography>
                        )}
                        <StyledTypographyGreen>
                            {showLost && (
                                <>{lostOrStolenDescription && renderRichText(lostOrStolenDescription, options)}</>
                            )}
                        </StyledTypographyGreen>
                    </Box>
                </StyledGrid>
                <Grid item xs={12} sx={{ padding: '4%', backgroundColor: '#E3E3E3' }}>
                    <Typography sx={{ fontSize: '16px', color: '#111' }}>{allTitles.noClaimsBenefitTitle} </Typography>
                </Grid>
                <StyledGridMiddle item xs={6} sx={{ padding: '4%' }}>
                    {checkImg}
                    <Box width="100%" display="flex" flexDirection="column">
                        <StyledTypography>{asurionPlanComparison.noClaimsDetails}</StyledTypography>
                        <StyledTypographyGreen>{asurionPlanComparison.noClaimsExpanderText}</StyledTypographyGreen>
                    </Box>
                </StyledGridMiddle>
                <StyledGrid item xs={6} sx={{ padding: '4%' }}>
                    {showRemarkIcon(planData.noClaimsRemark)}
                    <Box width="100%">
                        <StyledTypography>{noClaimsDetails}</StyledTypography>
                        {noClaimsRemark ? (
                            <StyledTypographyGreen>{noClaimsExpanderText}</StyledTypographyGreen>
                        ) : (
                            <StyledTypographyRed>{noClaimsExpanderText}</StyledTypographyRed>
                        )}
                    </Box>
                </StyledGrid>
                <Grid item xs={12} sx={{ backgroundColor: '#E3E3E3' }}>
                    <Accordion sx={{ backgroundColor: '#E3E3E3', padding: '0', border: 'none' }}>
                        <AccordionSummary
                            expandIcon={<CustomExpandIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography sx={{ fontSize: '16px', color: '#111' }}>
                                {allTitles.additionalExtraTitle}{' '}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                zIndex: '1',
                                background: 'white',
                                padding: 0,
                            }}
                        >
                            <Grid xs={12} container>
                                <Grid
                                    item
                                    xs={6}
                                    sx={{
                                        pl: 2,
                                        borderLeft: '3px solid #8223D2',
                                        borderBottom: '3px solid #8223D2',
                                        borderRight: '3px solid #8223D2',
                                        '& > ul': {
                                            px: 3,
                                        },
                                    }}
                                >
                                    {asurionPlanComparison.addedExtras &&
                                        renderRichText(asurionPlanComparison.addedExtras, options)}
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    sx={{
                                        pl: 2,
                                        '& > ul': { pl: 3 },
                                    }}
                                >
                                    {addedExtras && renderRichText(addedExtras, options)}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </>
    );

    const planComparisonDesktop: JSX.Element = (
        <Grid display="flex" flexDirection="column" container>
            <Grid display="flex" flexDirection="row" item container mb={1}>
                <Grid display="flex" flexDirection="column" justifyContent="center" md={4} p={3}>
                    <Box>
                        <Typography fontSize="1.5rem">
                            Based on {deviceTitle}. Compare Asurion mobile+ and {otherName}
                        </Typography>
                        <Typography
                            fontSize=".75rem"
                            textAlign="left"
                            color="text.disabled"
                            pt={2}
                            sx={{
                                '& >p>span': { fontSize: '0.75rem', lineHeight: '16px' },
                                '& >p>p': { fontSize: '0.75rem', lineHeight: '16px' },
                            }}
                        >
                            {renderRichText(allTitles.legalDisclaimerText, options)}
                        </Typography>
                    </Box>
                </Grid>
                <Grid
                    height="254px"
                    md={4}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    sx={{
                        border: '3px solid #8223D2',
                        background: 'white',
                        borderRadius: '8px',
                        borderTopRightRadius: '0',
                        borderBottomRightRadius: '0',
                    }}
                    p={2}
                    item
                >
                    {(monthlyPrice !== null && monthlyPrice !== '' && monthlyPrice !== ' ' && (
                        <Box>
                            <Typography fontSize="2rem" color="primary">
                                Asurion
                            </Typography>
                            <Typography fontSize="1.125rem">{asurionPlanName} Plan </Typography>
                            <Box>
                                <Typography component="span" fontSize="2rem" fontWeight="bold" color="text.priceGreen">
                                    ${asurionDevicePhonePricing.monthlyPrice}*
                                </Typography>
                                <Typography fontSize="1.125rem" component="span">
                                    /mth
                                </Typography>
                            </Box>
                        </Box>
                    )) || (
                        <Box>
                            <Typography fontSize="2rem" color="primary">
                                Asurion
                            </Typography>
                            <Typography fontSize="1.125rem">{asurionPlanName} Plan </Typography>
                            <Box>
                                <Typography component="span" fontSize="2rem" fontWeight="bold" color="text.priceGreen">
                                    ${asurionDevicePhonePricing.annualPrice}*
                                </Typography>
                                <Typography fontSize="1.125rem" component="span">
                                    /yr{' '}
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    <Box display="flex" flexDirection="column" alignItems="center">
                        {asurionPlanName.toLowerCase().includes('classic') ? (
                            <Link
                                href={`${process.env.GATSBY_DOMAIN_URL}${allTitles.classicPlanFsgpdsLink}`}
                                target="_blank"
                                color="secondary"
                                id="btn-home-sns-classic-fsg-pds"
                            >
                                {allTitles.classicPlanFsgpdsText}
                            </Link>
                        ) : (
                            <Link
                                href={`${process.env.GATSBY_DOMAIN_URL}${allTitles.prestigePlanFsgpdsLink}`}
                                target="_blank"
                                color="secondary"
                                id="btn-home-sns-prestige-fsg-pds"
                            >
                                {allTitles.prestigePlanFsgpdsText}
                            </Link>
                        )}
                    </Box>
                </Grid>
                <Grid
                    height="254px"
                    md={4}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    sx={{
                        background: 'white',
                        borderRadius: '8px',
                        borderTopLeftRadius: '0',
                        borderBottomLeftRadius: '0',
                    }}
                    p={2}
                    item
                >
                    <Box>
                        <Typography fontSize="2rem" color="primary">
                            {company}
                        </Typography>
                        <Typography fontSize="1.125rem">{otherName}</Typography>

                        <Box>
                            {(monthlyPrice !== null && monthlyPrice !== '' && monthlyPrice !== ' ' && (
                                <>
                                    <Typography
                                        component="span"
                                        fontSize="2rem"
                                        fontWeight="bold"
                                        color="text.priceRed"
                                    >
                                        ${monthlyPrice}*
                                    </Typography>
                                    <Typography fontSize="1.125rem" component="span">
                                        /mth
                                    </Typography>
                                </>
                            )) || (
                                <>
                                    <Typography
                                        component="span"
                                        fontSize="2rem"
                                        fontWeight="bold"
                                        color="text.priceRed"
                                    >
                                        ${isBiannual ? toAnnualPrice(annualPrice) : annualPrice}*
                                    </Typography>
                                    <Typography fontSize="1.125rem" component="span">
                                        {annualPriceText}
                                    </Typography>
                                </>
                            )}
                        </Box>
                        {/* {annualPrice && (
                            <Box>
                                <Typography component="span" fontSize="1rem">
                                    ${calculateMonthlyPayment(parseInt(annualPrice), isBiannual)}/mth
                                </Typography>
                                <Typography fontSize="1rem" component="span">
                                    {isBiannual ? ' (2 yr contract)' : ' (pay annually)'}
                                </Typography>
                            </Box>
                        )} */}
                    </Box>
                    <Box>
                        <Button
                            onClick={() => executeScrollCancel()}
                            variant="outlined"
                            color="primary"
                            id="btn-home-sns-how-to-cancel"
                            sx={{
                                width: '100%',
                                paddingTop: '8px',
                                paddingBottom: '8px',
                                textTransform: 'none',
                                fontSize: '1.25rem',
                                marginBottom: 4,
                            }}
                        >
                            How to cancel?
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Grid flexDirection="row" display="flex" justifyContent="center" textAlign="center" mb={2} container>
                <Grid md={4} item></Grid>
                <Grid md={8} item>
                    <Typography fontSize="16px">{allTitles.planSelectionPricingBasisText}</Typography>
                </Grid>
            </Grid>
            <Grid display="flex" flexDirection="row" container sx={{ textAlign: 'center' }}>
                <Grid md={4} sx={{ background: '#F2F2F2', borderBottom: '1px solid #E3E3E3' }} p={2} item></Grid>
                <Grid
                    md={8}
                    textAlign="center"
                    sx={{ background: '#F2F2F2', borderBottom: '1px solid #E3E3E3', textAlign: 'center' }}
                    p={2}
                    item
                >
                    <StyledGridServiceDesktop>
                        <Typography fontSize="1rem" fontWeight="bold" sx={{ textAlign: 'center', margin: '0 3%' }}>
                            {allTitles.serviceProvidedTitle}
                        </Typography>
                        {checkImgSmall}
                        <Typography sx={{ fontSize: '12px', textAlign: 'center', margin: '0.5% 5% 0 2%' }}>
                            {allTitles.serviceProvidedRemark1}
                        </Typography>
                        {crossImgSmall}
                        <Typography sx={{ fontSize: '12px', textAlign: 'center', margin: '0.5% 5% 0 2%' }}>
                            {allTitles.serviceProvidedRemark2}
                        </Typography>
                        {warningImgSmall}
                        <Typography sx={{ fontSize: '12px', textAlign: 'center', margin: '0.5% 5% 0 2%' }}>
                            {allTitles.serviceProvidedRemark3}
                        </Typography>
                    </StyledGridServiceDesktop>
                </Grid>
            </Grid>

            <Grid display="flex" flexDirection="row" item container>
                <Grid md={4} sx={{ background: '#E3E3E3' }} p={2}>
                    {allTitles.insuranceTitle}
                </Grid>
                <StyledGridTop md={4} p={2} item>
                    {checkImg}
                </StyledGridTop>
                <StyledGrid md={4} p={2} item>
                    {showRemarkIcon(planData.insuranceRemark)}
                    <StyledTypography>{insuranceDetails}</StyledTypography>
                </StyledGrid>
            </Grid>
            {(monthlyPrice !== null && monthlyPrice !== '' && monthlyPrice !== ' ' && (
                <Grid display="flex" flexDirection="row" container>
                    <Grid md={4} sx={{ background: '#E3E3E3' }} p={2} item>
                        {allTitles.annualPriceTitle}
                    </Grid>
                    <StyledGridMiddle md={4} p={2} item>
                        {checkImg}
                        <StyledTypography color="text.priceGreen" fontWeight="bold">
                            ${asurionDevicePhonePricing.annualPrice}
                        </StyledTypography>
                        <StyledTypography fontSize="1.125rem" sx={{ marginLeft: '0' }}>
                            /yr
                        </StyledTypography>
                    </StyledGridMiddle>
                    <StyledGrid md={4} p={2} item>
                        {annualPrice !== null && annualPrice !== '' && annualPrice !== ' ' ? (
                            <>
                                <StyledTypography color="text.priceRed" fontSize="1.125rem" fontWeight="bold">
                                    ${isBiannual ? toAnnualPrice(annualPrice) : annualPrice}
                                </StyledTypography>
                                <Typography fontSize="1.125rem">{annualPriceText}</Typography>
                            </>
                        ) : (
                            <StyledTypography component="span" fontSize="1.125rem">
                                None
                            </StyledTypography>
                        )}
                    </StyledGrid>
                </Grid>
            )) || (
                <Grid display="flex" flexDirection="row" container>
                    <Grid md={4} sx={{ background: '#E3E3E3' }} p={2} item>
                        {allTitles.monthlyPriceTitle}
                    </Grid>
                    <StyledGridMiddle md={4} p={2} item>
                        {checkImg}
                        <StyledTypography fontWeight="bold" color="text.priceGreen">
                            ${asurionDevicePhonePricing.monthlyPrice}
                        </StyledTypography>
                        <StyledTypography fontSize="1.125rem">/mth</StyledTypography>
                    </StyledGridMiddle>
                    <StyledGrid md={4} p={2} item>
                        {monthlyPrice !== null && monthlyPrice !== '' && monthlyPrice !== ' ' ? (
                            <>
                                <StyledTypography color="text.priceRed" fontSize="1.125rem" fontWeight="bold">
                                    ${monthlyPrice}
                                </StyledTypography>
                                <Typography fontSize="1.125rem">/mth</Typography>
                            </>
                        ) : (
                            <StyledTypography component="span" fontSize="1.125rem">
                                None
                            </StyledTypography>
                        )}
                    </StyledGrid>
                </Grid>
            )}

            <Grid display="flex" flexDirection="row" container>
                <Grid md={4} sx={{ background: '#E3E3E3' }} p={2} item>
                    {allTitles.phonePurchaseTitle}
                </Grid>
                <StyledGridMiddle md={4} p={2} item>
                    {checkImg}
                    <StyledTypography>{asurionPlanComparison.phonePurchaseDetails}</StyledTypography>
                    <StyledTypographyDisabled>
                        {asurionPlanComparison.phonePurchaseExpanderText}
                    </StyledTypographyDisabled>
                </StyledGridMiddle>
                <StyledGrid md={4} p={2} item>
                    {showRemarkIcon(planData.phonePurchaseRemark)}
                    <Box>
                        <StyledTypography>{phonePurchaseDetails}</StyledTypography>
                        <StyledTypographyDisabled>{phonePurchaseExpanderText}</StyledTypographyDisabled>
                    </Box>
                </StyledGrid>
            </Grid>
            <Grid display="flex" flexDirection="row" container>
                <Grid md={4} sx={{ background: '#E3E3E3' }} p={2} item>
                    {allTitles.olderPhonesTitle}
                </Grid>
                <StyledGridMiddle md={4} p={2} item>
                    {checkImg}
                    <Box>
                        <StyledTypography>{asurionPlanComparison.olderPhonesDetails}</StyledTypography>
                        <StyledTypographyDisabled>
                            {asurionPlanComparison.olderPhonesDescription}
                        </StyledTypographyDisabled>
                        <StyledTypographyDisabled>
                            {asurionPlanComparison.olderPhonesExpanderText}
                        </StyledTypographyDisabled>
                    </Box>
                </StyledGridMiddle>
                <StyledGrid md={4} p={2} item>
                    {showRemarkIcon(planData.olderPhonesRemark)}
                    <Box>
                        <StyledTypography>{olderPhonesDetails}</StyledTypography>
                        <StyledTypographyDisabled>{olderPhonesExpanderText}</StyledTypographyDisabled>
                    </Box>
                </StyledGrid>
            </Grid>
            <Grid display="flex" flexDirection="row" container>
                <Grid md={4} sx={{ background: '#E3E3E3' }} p={2} item>
                    {allTitles.renewalTitle}
                </Grid>
                <StyledGridMiddle md={4} p={2} item>
                    {showRemarkIcon(asurionPlanComparison.changePhoneOrPlanRemark)}
                    <Box>
                        <StyledTypography>{asurionPlanComparison.changePhoneOrPlanDetails}</StyledTypography>

                        {asurionPlanComparison.changePhoneOrPlanExpanderText && (
                            <StyledTypographyDisabled>
                                {asurionPlanComparison.changePhoneOrPlanExpanderText}
                            </StyledTypographyDisabled>
                        )}
                    </Box>
                </StyledGridMiddle>
                <StyledGrid md={4} p={2} item>
                    {showRemarkIcon(planData.changePhoneOrPlanRemark)}
                    <Box>
                        <StyledTypography>{changePhoneOrPlanDetails}</StyledTypography>
                        {changePhoneOrPlanExpanderText && (
                            <StyledTypographyDisabled>{changePhoneOrPlanExpanderText}</StyledTypographyDisabled>
                        )}
                    </Box>
                </StyledGrid>
            </Grid>
            <Grid display="flex" flexDirection="row" container>
                <Grid md={4} sx={{ background: '#E3E3E3' }} p={2} item>
                    {allTitles.cancelAnytimeTitle}
                </Grid>
                <StyledGridMiddle md={4} p={2} item>
                    {showRemarkIcon(asurionPlanComparison.changePhoneOrPlanRemark)}
                    <Box>
                        <StyledTypography>{asurionPlanComparison.cancelAnytimeDetails}</StyledTypography>

                        {asurionPlanComparison.cancelAnytimeExpanderText && (
                            <StyledTypographyDisabled>
                                {asurionPlanComparison.cancelAnytimeExpanderText}
                            </StyledTypographyDisabled>
                        )}
                    </Box>
                </StyledGridMiddle>
                <StyledGrid md={4} p={2} item>
                    {showRemarkIcon(planData.cancelAnytimeRemark)}
                    <Box>
                        <StyledTypography>{cancelAnytimeDetails}</StyledTypography>
                        {cancelAnytimeExpanderText && (
                            <StyledTypographyDisabled>{cancelAnytimeExpanderText}</StyledTypographyDisabled>
                        )}
                    </Box>
                </StyledGrid>
            </Grid>
            <Grid display="flex" flexDirection="row">
                <Grid textAlign="center" md={4} sx={{ background: '#4C147A' }}></Grid>
                <Grid
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    textAlign="center"
                    md={8}
                    sx={{ background: '#4C147A' }}
                >
                    <Typography color="text.white">Repair, Damage, Loss & Theft Coverage</Typography>
                </Grid>
            </Grid>
            <Grid display="flex" flexDirection="row" container>
                <Grid md={4} sx={{ background: '#F2F2F2', borderBottom: '1px solid #E3E3E3' }} p={2} item></Grid>
                <Grid
                    md={8}
                    textAlign="center"
                    sx={{ background: '#F2F2F2', borderBottom: '1px solid #E3E3E3' }}
                    p={2}
                    item
                >
                    <Typography fontSize="1rem" fontWeight="bold">
                        {allTitles.totalClaimTitle}
                    </Typography>
                </Grid>
            </Grid>

            <Grid display="flex" flexDirection="row" container>
                <Grid md={4} sx={{ background: '#F2F2F2' }} p={2} item></Grid>
                <Grid
                    md={4}
                    textAlign="center"
                    sx={{ background: '#F2F2F2', borderBottom: '1px solid #E3E3E3' }}
                    p={2}
                    item
                >
                    <Typography fontSize="1rem" color="text.priceGreen">
                        {asurionPlanComparison.breakdownText && asurionPlanComparison.breakdownText}
                    </Typography>
                </Grid>
                <Grid
                    md={4}
                    textAlign="center"
                    sx={{ background: '#F2F2F2', border: '1px solid #E3E3E3', borderRight: '0', borderTop: '0' }}
                    p={2}
                >
                    <Typography fontSize="1rem" color="text.priceRed">
                        {breakdownText}
                    </Typography>
                </Grid>
            </Grid>
            <Grid display="flex" flexDirection="row" container>
                <Grid md={4} sx={{ background: '#E3E3E3' }} p={2} item>
                    {allTitles.screenRepairTitle}
                </Grid>
                <StyledGridMiddle md={4} p={2} item>
                    {showRemarkIcon(asurionPlanComparison.screenPhoneRepairRemark)}
                    <Box>
                        <StyledTypography>{asurionPlanComparison.screenPhoneRepairDetails}</StyledTypography>
                        <StyledTypographyGreen>
                            {asurionPlanComparison.screenPhoneRepairDescription &&
                                renderRichText(asurionPlanComparison.screenPhoneRepairDescription, options)}
                        </StyledTypographyGreen>
                    </Box>
                </StyledGridMiddle>
                <StyledGrid md={4} p={2} item>
                    {showRemarkIcon(planData.screenPhoneRepairRemark)}
                    <Box>
                        {screenPhoneRepairRemark ? (
                            <>
                                <StyledTypography>{screenPhoneRepairDetails}</StyledTypography>
                                <StyledTypographyGreen>
                                    {screenPhoneRepairDescription &&
                                        renderRichText(screenPhoneRepairDescription, options)}
                                </StyledTypographyGreen>
                            </>
                        ) : (
                            <>
                                <StyledTypography color="text.priceRed">{screenPhoneRepairDetails}</StyledTypography>
                                <StyledTypographyRed>
                                    {screenPhoneRepairDescription &&
                                        renderRichText(screenPhoneRepairDescription, options)}
                                </StyledTypographyRed>
                            </>
                        )}
                    </Box>
                </StyledGrid>
            </Grid>
            <Grid display="flex" flexDirection="row" container>
                <Grid md={4} sx={{ background: '#E3E3E3' }} p={2} item>
                    {allTitles.damageReplacementUpgradeTitle}
                </Grid>
                <StyledGridMiddle md={4} p={2} item>
                    {showRemarkIcon(asurionPlanComparison.damageReplacementRemark)}
                    <Box>
                        <StyledTypography>{asurionPlanComparison.damageReplacementDetails}</StyledTypography>
                        {asurionPlanComparison.damageReplacementRemark ? (
                            <StyledTypographyGreen>
                                {asurionPlanComparison.damageReplacementDescription &&
                                    renderRichText(asurionPlanComparison.damageReplacementDescription, options)}
                            </StyledTypographyGreen>
                        ) : (
                            <StyledTypographyRed>
                                {asurionPlanComparison.damageReplacementDescription &&
                                    renderRichText(asurionPlanComparison.damageReplacementDescription, options)}
                            </StyledTypographyRed>
                        )}
                    </Box>
                </StyledGridMiddle>
                <StyledGrid md={4} p={2} item>
                    {showRemarkIcon(planData.damageReplacementRemark)}
                    <Box>
                        {damageReplacementRemark ? (
                            <>
                                <StyledTypography>{damageReplacementDetails}</StyledTypography>
                                <StyledTypographyGreen>
                                    {damageReplacementDescription &&
                                        renderRichText(damageReplacementDescription, options)}
                                </StyledTypographyGreen>
                            </>
                        ) : (
                            <>
                                <StyledTypography color="text.priceRed">{damageReplacementDetails}</StyledTypography>
                                <StyledTypographyRed>
                                    {damageReplacementDescription &&
                                        renderRichText(damageReplacementDescription, options)}
                                </StyledTypographyRed>
                            </>
                        )}
                    </Box>
                </StyledGrid>
            </Grid>
            <Grid display="flex" flexDirection="row" container>
                <Grid md={4} sx={{ background: '#E3E3E3' }} p={2} item>
                    {allTitles.lostOrStolenReplacementTitle}
                </Grid>
                <StyledGridMiddle md={4} p={2} item>
                    {showRemarkIcon(asurionPlanComparison.lostOrStolenRemark)}
                    <Box>
                        {asurionPlanComparison.lostOrStolenRemark ? (
                            <>
                                <StyledTypography>
                                    {asurionPlanComparison.lostOrStolenDetails &&
                                        asurionPlanComparison.lostOrStolenDetails}
                                </StyledTypography>
                                <StyledTypographyGreen>
                                    {asurionPlanComparison.lostOrStolenDescription &&
                                        renderRichText(asurionPlanComparison.lostOrStolenDescription, options)}
                                </StyledTypographyGreen>
                            </>
                        ) : (
                            <>
                                <StyledTypography color="text.priceRed">
                                    {asurionPlanComparison.lostOrStolenDetails &&
                                        asurionPlanComparison.lostOrStolenDetails}
                                </StyledTypography>
                                <StyledTypographyRed>
                                    {asurionPlanComparison.lostOrStolenDescription &&
                                        renderRichText(asurionPlanComparison.lostOrStolenDescription, options)}
                                </StyledTypographyRed>
                            </>
                        )}
                    </Box>
                </StyledGridMiddle>
                <StyledGrid md={4} p={2} item>
                    {showRemarkIcon(planData.lostOrStolenRemark)}
                    <Box>
                        {lostOrStolenRemark ? (
                            <>
                                <StyledTypography>{lostOrStolenDetails}</StyledTypography>
                                <StyledTypographyGreen>
                                    {lostOrStolenDescription && renderRichText(lostOrStolenDescription, options)}
                                </StyledTypographyGreen>
                            </>
                        ) : (
                            <>
                                <StyledTypography color="text.priceRed">{lostOrStolenDetails}</StyledTypography>
                                <StyledTypographyRed>
                                    {lostOrStolenDescription && renderRichText(lostOrStolenDescription, options)}
                                </StyledTypographyRed>
                            </>
                        )}
                    </Box>
                </StyledGrid>
            </Grid>
            <Grid display="flex" flexDirection="row" container>
                <Grid md={4} sx={{ background: '#E3E3E3' }} p={2} item>
                    {allTitles.noClaimsBenefitTitle}
                </Grid>
                <StyledGridMiddle md={4} p={2} item>
                    {showRemarkIcon(asurionPlanComparison.noClaimsRemark)}
                    <Box width="100%">
                        {asurionPlanComparison.noClaimsRemark ? (
                            <>
                                <StyledTypography>
                                    {asurionPlanComparison.noClaimsDetails && asurionPlanComparison.noClaimsDetails}
                                </StyledTypography>
                                <StyledTypographyGreen>
                                    {asurionPlanComparison.noClaimsExpanderText &&
                                        asurionPlanComparison.noClaimsExpanderText}
                                </StyledTypographyGreen>
                            </>
                        ) : (
                            <>
                                <StyledTypography color="text.priceRed">
                                    {asurionPlanComparison.noClaimsDetails && asurionPlanComparison.noClaimsDetails}
                                </StyledTypography>
                                <StyledTypographyGreen>
                                    {asurionPlanComparison.noClaimsExpanderText &&
                                        asurionPlanComparison.noClaimsExpanderText}
                                </StyledTypographyGreen>
                            </>
                        )}
                    </Box>
                </StyledGridMiddle>
                <StyledGrid md={4} p={2} item>
                    {showRemarkIcon(planData.noClaimsRemark)}
                    <Box>
                        {noClaimsRemark ? (
                            <>
                                <StyledTypography>{noClaimsDetails}</StyledTypography>
                                <StyledTypographyGreen>{noClaimsExpanderText}</StyledTypographyGreen>
                            </>
                        ) : (
                            <>
                                <StyledTypography color="text.priceRed">{noClaimsDetails}</StyledTypography>
                                <StyledTypographyRed>{noClaimsExpanderText}</StyledTypographyRed>
                            </>
                        )}
                    </Box>
                </StyledGrid>
            </Grid>
            <Grid display="flex" flexDirection="row" container>
                <Grid md={4} sx={{ background: '#E3E3E3' }} p={2} item>
                    {allTitles.additionalExtraTitle}
                </Grid>
                <StyledGridBottom
                    md={4}
                    sx={{
                        '& > ul': {
                            padding: 2,
                            m: 0,
                        },
                    }}
                    pl={2}
                    pr={2}
                    item
                >
                    {asurionPlanComparison.addedExtras && renderRichText(asurionPlanComparison.addedExtras, options)}
                </StyledGridBottom>
                <StyledGrid
                    md={4}
                    sx={{
                        '& > ul': {
                            padding: 2,
                            m: 0,
                        },
                    }}
                    pl={2}
                    pr={2}
                    item
                >
                    {addedExtras && renderRichText(addedExtras, options)}
                </StyledGrid>
            </Grid>
        </Grid>
    );

    return (
        <Container maxWidth="xl" sx={{ my: 6, maxWidth: '1400px !important' }}>
            {isMobile ? planComparisonSmall : planComparisonDesktop}
            <Grid item xs={12} sx={{ padding: '4%' }}>
                <Typography sx={{ fontSize: isSmall ? '.75rem' : '1.125rem', fontWeight: 'bold', textAlign: 'center' }}>
                    {renderRichText(policyDescription, options)}
                </Typography>
            </Grid>
        </Container>
    );
};

export default PlanComparison;
