import React from 'react';
import { Box, Container, Grid, Typography, Link, Button, MenuItem, FormControl, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

export interface HeroContainerDetails {
    backgroundImage: ImageDataLike;
    backgroundHeroImage: ImageDataLike;
    backgroundHeroImageTablet: ImageDataLike;
}

function HeroContainer({ heroContainerDetails, children }) {
    const theme = useTheme();
    const isSmall: Boolean = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile: Boolean = useMediaQuery(theme.breakpoints.down('sm'));

    const heroContainerDesktop: JSX.Element = (
        <BackgroundImage
            {...convertToBgImage(getImage(heroContainerDetails.backgroundImage))}
            preserveStackingContext
            style={{
                height: '100%',
                zIndex: 1,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
            loading="eager"
        >
            <BackgroundImage
                {...convertToBgImage(getImage(heroContainerDetails.backgroundHeroImage))}
                preserveStackingContext
                style={{
                    height: '100%',
                    width: '100%',
                    zIndex: 1,
                    backgroundSize: 'contain',
                    backgroundPosition: 'bottom',
                }}
                loading="eager"
            >
                <Container sx={{ height: '100%', maxWidth: '1400px !important' }}>{children}</Container>
            </BackgroundImage>
        </BackgroundImage>
    );
    const heroContainerTablet: JSX.Element = (
        <BackgroundImage
            {...convertToBgImage(getImage(heroContainerDetails.backgroundHeroImageTablet))}
            preserveStackingContext
            style={{
                height: '100%',
                zIndex: 1,
                backgroundSize: 'contain',
                backgroundPosition: '80%, bottom',
            }}
            loading="eager"
        >
            <Container sx={{ height: '100%', maxWidth: '1400px !important' }}>{children}</Container>
        </BackgroundImage>
    );
    const heroContainerMobile: JSX.Element = (
        <BackgroundImage
            {...convertToBgImage(getImage(heroContainerDetails.backgroundImage))}
            preserveStackingContext
            style={{
                height: '100%',
                zIndex: 1,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
            loading="eager"
        >
            <Container sx={{ height: '100%', maxWidth: '1400px !important' }}>{children}</Container>
        </BackgroundImage>
    );
    return (
        <Box
            height={isMobile ? '732px' : isSmall ? '622px' : '822px'}
            sx={{
                background: 'linear-gradient(118.74deg, #621A9E 1.06%, #340E53 100%)',
            }}
        >
            {isMobile ? heroContainerMobile : isSmall ? heroContainerTablet : heroContainerDesktop}
        </Box>
    );
}

export default HeroContainer;
